import routes from './routes'
const install = Vue => {
    Vue.mixin({
        data() {
            return {
                clientId: 2, //live
                //clientId : 6, //dev
                parman: 1,
                liffId: process.env.VUE_APP_LIFFID,
                //URL: 'http://localhost:8000',
                URLIMAGE: 'https://line.elebox.id/img',
                URL: 'https://line.elebox.id/api/client',
                URLLOCAL: 'http://localhost',
                //URL: 'https://line-dev.elebox.id/api/client',
                version: process.env.VERSION,
                elMessage: '',
                elIsLoading: false,
                elIsDialog: false,
                elDialogCallback: undefined,
                prefix: [],
                payments: [],
                customerid: '',
                isConfirm: false,
                isPayment: false,
                isPageOffset: 0,
                confirmationData: undefined,
                payment: null,
                dvm: 0,
                carvendor : '',
                locationDvm: '',
                stackhistory: []
            }
        },
        methods: {
            elLoadings(show, message) {
                this.elIsLoading = show
                this.elMessage = message
            },
            elDialog(message, callback) {
                this.elIsDialog = true
                this.elMessage = message
                this.elDialogCallback = callback
                window.history.pushState(
                    null,
                    this,
                    '#dialog'
                )
            },
            elGet(path) {

                const headers = {
                    "Authorization": "Bear " + this.$root.access_token,
                    'Client-Id': this.clientId,
                    'liffId': this.liffId
                };
                var url = '';
                if (this.dvm == 1) {
                    url = '&dvm=true';
                }
                return fetch(this.URL + path + url, {
                    method: 'GET',
                    mode: 'cors',
                    headers: headers
                })
                    .then(response => response.json())
            },
            elPost(path, data) {
                var headers = {
                    "Content-Type": "application/json",
                    'liffId': process.env.VUE_APP_LIFFID
                }
                if (this.$root.client) {
                    headers = {
                        "Authorization": "Bear " + this.$root.access_token,
                        'Client-Id': this.clientId,
                        "Content-Type": "application/json",
                        'liffId': process.env.VUE_APP_LIFFID
                    };
                }
                return fetch(this.URL + path, {
                    method: 'POST',
                    mode: 'cors',
                    headers: headers,
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
            },
            getPrefix(phone) {
                phone = phone.trim()
                if (phone.length < 4) return ''
                if (phone.startsWith('+62')) {
                    if (phone.length < 6) {
                        return phone.substring(3)
                    }
                    return phone.substring(3, 6)
                }
                if (phone.startsWith('62')) {
                    if (phone.length < 5) {
                        return phone.substring(2)
                    }
                    return phone.substring(2, 5)
                }
                if (phone.startsWith('0')) {
                    return phone.substring(1, 4);
                }
                return ''
            },
            formatCurrency(number) {
                let x = 0
                let n = 0
                var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
                return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
            },
            go(href, replace) {
                let ss = href.split('?')
                this.$root.currentRoute = href
                if (ss.length > 1) {
                    this.$root.searchRoute = '?' + ss[1]
                } else {
                    this.$root.searchRoute = ''
                }
                if (replace) {
                    window.history.replaceState(
                        null,
                        routes[this.href],
                        this.href
                    )
                } else {
                    window.history.pushState(
                        null,
                        routes[this.href],
                        this.href
                    )
                }
            },
            callBackFunction() {
                console.log('callBackFunction')
            },
            saveObject(name, obj) {
                if (!obj) {
                    localStorage.removeItem(name);
                } else {
                    localStorage.setItem(name, JSON.stringify(obj));
                }
            },
            loadObject(name) {
                let json = localStorage.getItem(name);
                if (!json) {
                    return null
                }
                return JSON.parse(json)
            }
        }
    })
}

export default {
    install
};