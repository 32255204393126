<template>
    <div class="marginContent">
      <div>
        <div style="margin-top:5vmin;">
            <h4>Register</h4>
        </div>
        <div>Selamat Datang di menu PPOB, Anda pertama kali mengakses menu ini silahkan isi beberapa data berikut:</div>
        <div class="form-element">
            <input class="input-customerid" v-model.trim="name" placeholder="Nama"/>
        </div>
        <div class="form-element">
                <input type="email" class="input-customerid" v-model.trim="email" placeholder="Email"/>
            </div>
            <div class="form-element">
                <input type="tel" class="input-customerid" v-model.trim="phone" placeholder="Nomor telephone"/>
            </div>
            <div class="form-element">
                <input v-model="aggree" placeholder="Nomor telephone" type="checkbox"/> Setuju dengan <a @click="showTOC()">Syarat & Ketentuan</a>
            </div>
            <div class="form-element">
            <button class="buttonPrimary footerButton" style="width:100%" @click="submit()">Daftar</button>
            </div>
    </div>

    <div v-if="isShowTOC" class="modal-mask">
        <!-- Modal content -->
            
              <div>
                <div class="text-center titlePayment">
                  <img src="@/assets/ic_back.png" style="float:left" @click="isShowTOC = false">
                  <b>Syarat dan ketentuan</b>
                </div>
              </div>
              <div class="toc-context">
<textarea class="toc" readonly disabled v-model="message">
                  </textarea>
                  <button class="buttonPrimary footerButton" style="width:100%" @click="isShowTOC = false">Tutup</button>
              </div>
    </div>
  </div>
</template>

<script>
import MyMixin from '../mixins/mixin'

export default {
  name: 'Register',
  mixins: [MyMixin],
  data() {
    return {
      name:'',
      phone:'',
      email:'',
      aggree: false,
      isShowTOC:false,
      message:`
Selamat datang di AP2 PPOB Disarankan sebelum mengakses fitur ini lebih jauh, Anda terlebih dahulu membaca dan memahami syarat dan ketentuan 
yang berlaku. Syarat dan ketentuan berikut adalah ketentuan dalam pengunjungan Situs, isi dan/atau konten, layanan, 
serta fitur lainnya yang ada di dalam Situs. Dengan mengakses atau menggunakan Situs atau aplikasi lainnya yang 
disediakan oleh atau dalam Situs, berarti Anda telah memahami dan menyetujui serta terikat dan tunduk dengan segala 
syarat dan ketentuan yang berlaku di Situs ini.

1. DEFINISI
Setiap kata atau istilah berikut yang digunakan di dalam Syarat dan Ketentuan ini memiliki arti seperti berikut di bawah, kecuali jika kata atau istilah yang bersangkutan di dalam pemakaiannya dengan tegas menentukan lain
1.1. “Kami”, berarti PT NFC selaku pemilik dan pengelola Situs serta aplikasi mobile yang bernama AP2 PPOB.
1.2. “Anda”, berarti tiap orang yang mengakses Situs dan menggunakan layanan dan jasa yang disediakan oleh Kami.
1.3. “Layanan”, berarti setiap dan keseluruhan jasa serta informasi yang ada pada Situs, termasuk namun tidak terbatas pada informasi yang disediakan, fitur dan layanan aplikasi, dukungan data, serta aplikasi mobile yang Kami sediakan.
1.4. “Pengguna”, berarti tiap orang yang mengakses dan menggunakan layanan dan jasa yang disediakan oleh Kami, termasuk diantaranya Pengguna Belum Terdaftar dan Pengguna Terdaftar.
1.5. "Pengguna Belum Terdaftar", berarti tiap orang yang mengakses Situs Kami dan belum melakukan registrasi.
1.6. “Pengguna Terdaftar”, berarti tiap orang yang mengakses dan menggunakan layanan dan jasa yang disediakan oleh Kami, serta telah melakukan registrasi dan memiliki akun pada Situs Kami.
1.7. “Pihak Ketiga”, berarti pihak lainnya, termasuk namun tidak terbatas pihak bank, multifinance, telekomunikasi yang menyediakan layanannya dalam Situs.
1.8. “Informasi Pribadi”, berarti tiap dan seluruh data pribadi yang diberikan oleh Pengguna di Situs Kami, termasuk namun tidak terbatas pada nama lengkap, nomor telephone, dan email.
1.9. “Konten”, berarti teks, data, informasi, angka, gambar, grafik, foto, audio, video, nama pengguna, informasi, aplikasi, tautan, komentar, peringkat, desain, atau materi lainnya yang ditampilkan pada Situs.
1.10. “Situs”, berarti AP2 PPOB


2. LAYANAN DAN/ATAU JASA
3. PENGGUNAAN LAYANAN DAN JASA
4. HAK INTELEKTUAL PROPERTI
5. KOMENTAR
6. PENGAJUAN
7. TAUTAN LAINNYA
8. ATURAN KEANGGOTAAN
9. UMUM
`
    }
  },
  methods: {
    showTOC() {
        this.isShowTOC = true
    },
    nothing() {

    },
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    submit() {
      this.errormessage = '';
      let self = this
      if (this.email.length == 0 || this.name.length == 0 || this.phone.length == 0) {
          self.$root.elDialog('Mohon isi semua field')
          return
      }
      if (!this.validateEmail(this.email)) {
          self.$root.elDialog('format email salah')
          return
      }
      if (!this.aggree) {
          self.$root.elDialog('Anda belum setuju syarat dan ketentuan')
          return
      }
      
      this.$root.elLoadings(true, 'Loading')
      this.$root.elPost('/ap2/register',{
          uid: this.$root.uid,
          name: this.name,
          email: this.email,
          phone: this.phone,
        })
          .then(data => {
            this.$root.elLoadings(false)
            if (data.ec == 200) {
              self.$root.client = data.client
              self.$root.ads = data.ads
              self.$root.access_token = data.access_token
              self.$root.liffCreated = true
              self.$root.needRegister = false
              this.$emit('getData')
            } else {
              self.$root.elDialog(data.message)
            }
          });
    }
  },
  mounted() {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: table;
  animation-duration: 0.3s;
  background: #fff;
  padding: 2%;
}
h4 {
  font-size:16pt;
}

.form-element {
    padding: 4px;
}
.toc {
    width: 93%;
    height: 80%;
    padding: 5px;
}
.toc-context {
    height: 100%;
    width: 100%;
}
</style>
