import Vue from 'vue'
import App from './App.vue'
import global from './global'
import './styles/app.styl';
import routes from './routes'
import vSelect from 'vue-select'

Vue.config.productionTip = false
Vue.prototype.$liff = window.liff
Vue.use(global);
Vue.use(require('vue-moment'));
Vue.component('v-select', vSelect)

const app = new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname,
    searchRoute: window.location.search,
    liffCreated: true,
    menuCreated: false,
    needRegister: false,
    customerids: {},
    ads:[],
    client: {
      id : 6
      //payment : ''
    },
    uid: '1234567',
    mainProduct: null,
    access_token:'zQJoV35z8vL6gsEtUzqEiB5hZ22Ba37nj8' //live
    //access_token:'WLIlONFiAwH0uPclJAsR'// dev
  },
  beforeCreate () {
    
  },
  computed: {
    ViewComponent () {
      console.log('ViewComponent',this.currentRoute,this.searchRoute)
      for (const r in routes) {
        if (this.currentRoute.startsWith(r)) {
          document.title = routes[r].title
          return routes[r].component
        }
      }
      document.title = 'Topup & Tagihan'
      return App
    }
  },
  mounted() {
    
    let params = this.parseQueryPath(this.searchRoute)
    var uid = null;
    if (params.uid) {
      uid = params.uid
    } else {
      uid = this.getCookie('app-uid')
    }
    if (params.template) {
      this.mainProduct = {
        template:params.template,
        param1: params.param1
      }
    }
    console.log('main.mounted.uid:', params, uid)
    if (uid) {
      this.uid = uid 
      this.checkLogin()
    } else {
      //this.liffCreated = true
    }
  },
  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      console.log('cookies',decodedCookie)
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    parseQueryPath(query) {
      if (!query) return {}
      var qq = query.split('?')
      var qq2
      if (qq.length == 1) {
          qq2 = query
      } else {
          qq2 = qq[1]
      }
      var r = {}
      qq = qq2.split('&')
      for (let i in qq) {
          let q = qq[i].split('=')
          if (q.length == 2) {
              r[q[0]] = q[1].replaceAll('+',' ')
          }
      }
      console.log('parseQueryPath', r)
      return r
    },
    checkLogin() {
      console.log('checkLogin')
      // GET request using fetch with set headers
      let self = this
      this.elPost('/ap2/auth',{
        uid : this.$root.uid,
        //access_token: this.$root.access_token
      })
        .then(data => {
          if (data.ec == 200) {
            self.client = data.client
            self.access_token = data.access_token
            self.ads = data.ads
            if (data.products) {
              self.customerids = data.products
            }
            self.liffCreated = true
            self.$forceUpdate();
            console.log('updated')
          } else if (data.ec == 404) {
            self.needRegister = true
          }
        });
    }
  },
  render (h) { return h(this.ViewComponent) }
})

window.addEventListener('popstate', (ev) => {
  console.log('popstate',ev.state)
  console.log('history', window.history)
  app.currentRoute = window.location.pathname
  app.searchRoute = window.location.search
  if (app.elIsDialog) {
    app.elIsDialog = false
  } else if (app.isPayment) {
    app.isPayment = false
  } else if (app.isConfirm) {
    app.isConfirm = false
  } else if (app.isPageOffset > 0) {
    app.isPageOffset--
  }
})
