<template>
  <Layout :hasBack="true" title="Data"  @onBack="childBack">
    <div style="margin-top:5vmin;">
      <h4>
          No. Handphone
      </h4>
    </div>
    <input class="input-customerid" type="number" pattern="\d*" v-model.trim="customerid" @input="onchange"
      :style="{ backgroundImage: 'url(' + operator_img + ')' }" placeholder="Masukkan Nomor HP" ref="inputcustomerid"
    />
    <i v-if="operator_img"><img src="@/assets/ic-clear.svg"  class="clearButton" @click="clearCusId()" /></i>
    <div class="error" v-if="errormessage">{{errormessage}}</div>
    <div class="listProduct">
        <div class="listProductData" v-for="d in denoms"  :key="d.id" v-bind:class="{ '': denom && denom.id == d.id, 'li-product-inactive':d.status != 1}" @click="choose(d)">
          <div class="listTitle">
            <h1>{{d.name}} {{d.quota}}</h1>
            <div>{{d.description}}</div>
          </div>
          <div class="listPrice">
            <div class="text-gray">{{d.period}}</div>
            <div class="priceDetail text-bluegreen">Rp {{formatCurrency(d.price)}}</div>
          </div>
          <div class="clear"></div>
        </div>
    </div>
  </Layout>
  
</template>

<script>

import MyMixin from '../mixins/mixin'
import PulsaMixin from '../mixins/pulsa'
import Layout from './Layout.vue'
export default {
  name: 'PulsaData',
  components: { 
    Layout
  },
  mixins: [MyMixin, PulsaMixin],
  data() {
    return {
      product:undefined,
      pulsa: undefined
    }
  },
  methods: {
    clearCusId(){
      this.customerid = '';
      this.onchange();
    }

  },
  mounted() {
    this.product = this.loadObject('product')
    this.pulsa = this.product.products
    document.title = "Data"
    this.$refs.inputcustomerid.focus()    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.priceDetail{
    margin-top: 50px;
    font-weight: 700;
    font-size: 15px;
}
.listPrice{
    padding-top: 10px;
    width: 30%;
    float: left;
    text-align: right;
}
.listTitle{
  width: 70%;
  float: left;
}

.listProductData{
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.listProductData:active{
  background-color:#f5f5f5;
}
.active {
  background:#0C8C8C;
}
.listProduct {
  margin-top: 10px;

}
.listProduct h1{
  /* position: absolute; */
  height: 18px;
  left: 25px;
  top: 231px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
}

</style>
