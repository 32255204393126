export default {
    data () {
        return {
            operator_img: '',
            denom: undefined,
            denoms:[],
            operator: null,
            prefix: null,
            customerid: '',
            errormessage: ''
        }
    },
    methods: {
      childBack() {
        return window.history.back()
      },
        nothing(){
            return false
          },
          choose(denom) {
            if (denom.status != 1) {
              return
            }
            if (!this.denom || this.denom.id != denom.id) {
              this.denom = denom
            }
            
            if (this.customerid != '') {
              this.onConfirm({
                label_customerid: 'No HP',
                customerid: this.customerid,
                price: this.denom.price,
                item: this.denom,
                product: this.operator,
                name: this.denom.name,
                quota: this.denom.quota
              })
            }
          },
          onchange() {
            if (this.$options.name == 'Pulsa' || 'PulsaData' == this.$options.name) {
              if (!this.pulsa) {
                return
              }
              let p = this.getPrefix(this.customerid)
              this.$root.customerid = this.customerid
              var noop = true
              if (p != this.prefix) {
                  this.prefix = p
                  let op = this.$root.prefix[p]
                  if (op) {
                      var i = 0
                      for(; i < this.pulsa.length; i++) {
                          if (this.pulsa[i].operator == op) {
                              this.operator = this.pulsa[i]
                              this.denoms = this.operator.item
                              this.operator_img = this.operator.icon
                              noop = false
                              break
                          }
                      }
                  }
                  if (noop) {
                    this.operator = undefined
                    this.denoms = []
                    this.operator_img = null
                    if (this.customerid.length>6) {
                      this.errormessage = 'Nomor yang dimasukkan belum benar. Cek lagi yaa'
                    } else if (this.customerid.length < 3) {
                      this.errormessage = ''
                    }
                  } else {
                    this.errormessage = ''
                  }
              } else if (this.customerid.length>6) {
                if (!this.operator) {
                  this.errormessage = 'Nomor yang dimasukkan belum benar. Cek lagi yaa'
                }
              }
              console.log('errmessage', this.errormessage)
            }
          },
    }
  }