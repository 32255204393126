<template>
  <Layout :hasBack="false" :title="title"  @onBack="childBack" :csNumber="''">
    <div v-if="!$root.liffCreated && !$root.needRegister" class="loading">
      <img src="@/assets/loading.gif">
    </div>
    <div v-else-if="$root.needRegister">
      <Register v-on:getData="getData"/>
    </div>
    <div v-else>
      <div v-if="!$root.uid">
        No UID
      </div>
      <div v-else> 
        <div v-for="mm in menus" :key="mm.title" :class="dvm ? 'dvm' : ''">
          <div class="titleData">{{mm.title}}</div>
          <ul>
            <li v-for="m in mm.menu" :key="m.id" @click="onMenu(m)" :class="{'inactive':m.status == 2}">
              <div><img :class="dvm ? 'menu_img_dvm' : 'menu_img'" v-bind:src="m.icon"/></div>
              <div class="caption">{{m.caption}}</div>
            </li>
          </ul>
        </div>

        <div v-if="dvm">
          <div class="titleData">Service</div>
          <ul>
            <li @click="onMenu(99)">
              <div>
                <img :class="dvm ? 'menu_img_dvm' : 'menu_img'"  v-bind:src="URLIMAGE + '/invoice.png'"/>
              </div>
              <div class="caption">Print Struk</div>
            </li>
          </ul>
        </div> 
      </div>
    </div>
  </Layout>
</template>
<script>
import MyMixin from './mixins/mixin'
import Layout from './components/Layout.vue'
import Register from './components/Register.vue'
export default {
  name: 'App',
  components: {
    Layout,
    Register
  },
  mixins: [MyMixin],
  data() {
    return {
      isPayment: false,
      isLoading: false,
      isStruk: false,
      dataStatus: undefined,
      trxid: undefined,
      menus: undefined,
      confirm: null,
      history: null,
      product: null,
      location:{},
      title:'Loading'
    }
  },
  watch: {
   '$root.liffCreated': function(value){
      console.log('watch liffcreated',this.$root.ads);
      if (value) {
        this.title = ''
        if (!this.menu) {
          this.getData()
        }
      }
    }
  },
  methods: {
    clickedDownload() {
      const link = document.createElement('a');
      link.href = 'https://manfikar.com/img/manfikar-logo.png';
      link.setAttribute('download', 'file.png'); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    childBack() {
        return window.history.back()
      },
    login() {
      this.$liff.login()
    },
    getProfile () {
      let _this = this
      this.$liff.getProfile().then(function (profile) {
        _this.profile = profile
        console.log('profile', profile)
      }).catch(function (error) {
        alert('Error getting profile: ' + error)
      })
    },
    updateMenu(menu) {
        console.log('updateMenu', menu,this.$root.mainProduct)
        if (!menu) {
          return
        }
        var menus = [];
        var mainProduct = null
          for(var i=0; i<menu.length;i++) {
            if (this.$root.mainProduct) {
              if (this.$root.mainProduct.template == menu[i].template) {
                menu[i].product.param1 = this.$root.mainProduct.param1
                mainProduct = menu[i]
              }
            }
            var j = 0;
            for(;j<menus.length;j++) {
              if (menus[j].title == menu[i].category) {
                menus[j].menu.push(menu[i])
                break
              }
            }
            if (j>=menus.length) {
              menus.push({
                title: menu[i].category,
                menu: [menu[i]]
              })
            }
          }
          console.log('mainProduct', mainProduct)
          this.menus = menus
          this.$root.menuCreated = true
          if (mainProduct) {
            this.onMenu(mainProduct)
          }
      },
    getMenu() {
      console.log('getMenu')
      let self = this
      if (!this.$root.uid) {
        return
      }
       this.$root.elGet('/product?version=' + process.env.VUE_APP_VERSION + '')
          .then(data => {
            if (data.ec == 200) {
              self.$root.prefix = data.prefix
              self.$root.payments = []
              for(var i = 0; i < data.payments.length; i++) {
                if (data.payments[i].status == 1) {
                  self.$root.payments.push(data.payments[i])
                }
                if (data.payments[i].code == self.$root.client.payment) {
                  self.$root.payment = data.payments[i]
                  //break
                }
              }
              self.updateMenu(data.menu)
              self.saveObject('payments', data.payments)
            }
          });
    },
    getHistory() {
      this.$root.elGet('/trx')
          .then(data => {
            if (data.ec == 200) {
              this.history = data.trxs
            }
          });
    },
    getData() {
        console.log('getData')
        if (!this.$root.uid) {
          return
        }
        this.getMenu()
    },
    onMenu(menu) {
      if (menu.status == 2) {
        return
      }else if (menu == 99){
        this.go('/reprintstruk')
      }
      console.log('onMenu', menu)
      
      if (menu.template == 'PULSA' || menu.template == 'DATA'){
        this.saveObject('product', menu)
        if (menu.template == 'PULSA') {
          this.go('/pulsa')
        } else if (menu.template == 'DATA') {
          this.go('/data')
        }
      } else {
        this.saveObject('product', menu.product)
        
         
         
        if (menu.product && this.$root.customerids[menu.product.id]) {
          localStorage.defaultcustomerid = this.$root.customerids[menu.product.id]
        } else {
          localStorage.removeItem('defaultcustomerid')
        }
        if (menu.template == 'BILL') {
          //localStorage.product = menu.product
          this.go('/bill')
        } if (menu.template == 'BILLINQ') {
          this.go('/inqbill')
        } if (menu.template == 'POTER') {
          this.go('/poter')
        } if(menu.template == 'CARRENT'){
          this.go('/carrent')
        } if (menu.template == 'FOOD') {
          this.saveObject('product', menu.products[0])
          this.go('/food')
        } if(menu.template == 'CUSTOM'){
          this.go('/product')
        } 
      }
    },
    nothing() {
    },
    dialogClose(){
      this.$root.elIsDialog = false
      if (this.$root.elDialogCallback) {
        this.$root.elDialogCallback()
      }
    },
    choosePayment(payment) {
      this.payment = payment
      this.isPayment = false
    },
    showTrx(id) {
      this.go('/detail?trxid=' + id)
      /*this.isStruk = true
      this.trxid = id*/
    },
    closeTrxDetail() {
      this.isStruk = false
    }
  },
  mounted () {
    let params = this.$root.parseQueryPath(this.$root.searchRoute)
    console.log('app.mounted.params',params, this.$root.liffCreated)
    if(params.carvendor){
      this.$root.carvendor = params.carvendor; 
    }else{
      this.$root.carvendor = '';
    }
    
    console.log(this.carvendor);

    if (params.trxid) {
      if (localStorage.trxid != params.trxid) {
        this.go('/detail?trxid=' + params.trxid + '&uid=' + params.uid)
        localStorage.trxid = params.trxid
        return
      }
    }
    
    if (this.$root.liffCreated) {
      this.getData()
    }
  }
}
</script>

<style>
  .skContent{
        height: 500px;
      overflow: auto;
      margin-top: 25px;
      padding: 10px;
  }
  .pg_icon{
    max-width:100% ;
  }
  .titlePayment{
    font-size: 16px;
    color: rgb(17 17 17);
    font-weight: bold;
  }

  .paymentBox{
      margin-top: 30px;
  }
  .paymentBox .payment{
    border-bottom: 1px solid #f1f1f1;
        padding: 24px 20px;
    vertical-align: middle;
  }
  .mainContent{
    min-height: 560px;
  }
  h1 {
    color: #111;
    font-size: 1.5em;

  }
  h1 .green {
    color: #07B53B;
  }
  h5 {
    color:#dcdcdc;
    font-size: 14px;
  }
  .loading {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: -32px;
  }

  .tabMain {
    list-style-type: none;
    padding: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 16px;
  }
  .tabItem:last-child {
    margin-right:0 ;
  }

  .tabItem:first-child {
    margin-left:0 ;
  }
  input{
        font-family: SF Pro Display, Helvetica,Roboto, Arial, sans-serif !important;
  }
  .tabItem {
    font-size: 18px;
    line-height: 22px;
    display: inline-block;
    margin: 0 6px;
    color: #b7b7b7;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  a {
    color: #42b983;
  }

  .tabItem-active {
      color:#111111;
      border-bottom: 2px solid #333;
  }

  .product_image {
    height: 32px;
    align-self:flex-end;
  }

  .description {
    display: flex; 
    flex-direction:row;
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;
  }
  .description-row {
    border-bottom: 1px solid #EFEFEF;
  }
  .item-left {
    
  }
  .headerMain{
    font-weight: bold;
    margin-top: 20px;
  }
  
  .info-right{
    width: 25%;
    float: right;
    text-align: right;
  }

  .info-right-payment{
    width: 30%;
    float: right;
    text-align: center;
    background: rgb(16 117 117);
    color: #fff;
    font-size: 11px;
    padding:5px 3px ;
    border-radius: 15px;
  }

  .info-right-payment-noneActive{
    width: 30%;
    float: right;
    text-align: center;
    background: rgb(200 200 200);
    color: #fff;
    font-size: 11px;
    padding:5px 3px ;
    border-radius: 15px;
  }

  .titleHeader{
    color: rgb(183 183 183);
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .contentchild ul li {
    display: inline-block;
    margin: auto;
    vertical-align:top;
    text-align: center;
    width: 25%;
    height: 7em;
    font-size: 10pt;
    word-wrap: break-word;
  }

  .dvm ul li{
    height: 15em;
    font-size: 16pt;
  }
  .menu_a {
    color: #42b983;
  }
  .menu_img {
    width: 30pt;
    height: 30pt;
  }

  .menu_img_dvm {
    width: 100pt;
    height: 100pt;
  }
  .caption {
    color:#949494;
  }
  .inactive {
    opacity: 0.25;
  }
</style>
