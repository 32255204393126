<template>
  <div class="detail-body" style="padding-top:10px">
    <div v-if="!payment" class="loading">
      <img src="@/assets/loading.gif">
    </div>
    <div v-else>
      <div v-if="payment">
        <div class="detail-header" :class="{'wait':payment.status == 'wait','success':payment.result == 'success','failed':payment.result=='failed'}">
          <img style="float:left;margin-left: 18px;margin-top: 10px;" src="@/assets/ic_back.png" @click="back()">
          <span v-if="payment.status == 'wait'"  @click="load()"><img src="@/assets/ic_status_wait.svg" class="imgSuccess">
            {{ timeMinut }}:{{ (timeSecon > 9 ?  timeSecon : '0' + timeSecon) }} Waiting for Payment
          </span>
          <span v-if="payment.status == 'expire'"><img src="@/assets/ic_status_cancel.svg" class="imgSuccess"> Payment Canceled</span>
          <div v-if="payment.status == 'done'">
            <span v-if="payment.result == 'success'" ><img src="@/assets/ic_status_success.svg" class="imgSuccess"> Payment Success</span>
            <span v-if="payment.result == 'failed'" ><img src="@/assets/ic_status_failed.svg" class="imgSuccess"> Payment Failed</span>
          </div>
        </div>
        <div v-if="ovo">
          Please open OVO apps
        </div>
        <div class="mainContentDetail">
          <div class="rowData">
            <div class="leftData">
              <div><b class="title">{{payment.product.name}}</b></div>
              <div>{{payment.product.description}} - {{payment.customerid}}</div>
            </div>
            <div class="rightData">
                <img :src="payment.product.icon" class="imageOperator">
            </div>
            <div class="clear"></div>
          </div>

          <div class="rowData">
            <div class="leftData">
              <div>ID<b> {{payment.trxid}}</b></div>
              <div class="rowDataSpace">Time <b>{{payment.time}}</b></div>
            </div>
            <div class="rightData">
              <div class="noteHelp">Help?</div>
            </div>
            <div class="clear"></div>
          </div>

          <div v-if="special" class="special">

            <div class="special-value">{{special.value}}</div>
            <div class="special-label">{{special.label}}</div>
          </div>
          <div class="rowDataSpace">
            <div class="titleDataDetail"><b>Payment Detail</b></div>
          </div>
          
          <div class="rowDataSpace" v-for="info in payment.info"  :key="info.key">
            <div v-if="info.value">
              <div class="leftData">
                  {{ info.label }}
                </div>
                <div class="rightData">
                  <div class="">{{ info.value }}</div>
                </div>
                <div class="clear"></div>
            </div>
          </div>

          <div class="rowDataSpace" v-if="payment.admin_fee > 0">
            <div class="leftData sub_total">
              <b>Admin Fee</b>
            </div>
            <div class="rightData sub_total">
              <div class="">Rp {{formatCurrency(payment.admin_fee)}}</div>
            </div>
            <div class="clear"></div>
          </div>

          <div class="rowDataSpace" v-if="payment.fee>0">
            <div class="leftData sub_total">
              <b>Admin Fee (Payment)</b>
            </div>
            <div class="rightData sub_total">
              <div class="">Rp {{formatCurrency(payment.fee)}}</div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="rowDataSpace" v-if="payment.discount>0">
            <div class="leftData total">
              <b>Discount</b>
            </div>
            <div class="rightData total">
              <div class="">- Rp. {{formatCurrency(payment.discount)}}</div>
            </div>
            <div class="clear"></div>
          </div>

          <hr class="dote">
          <div class="rowDataSpace">
            <div class="leftData total">
              <b>Total</b>
            </div>
            <div class="rightData total">
              <div class="">Rp. {{formatCurrency(payment.price)}}</div>
            </div>
                        <div class="clear"></div>

          </div>
        </div>

        <div class="text-center" v-if="payment.payment_info && payment.payment_info.qr"> 
          <VueQrcode :value="payment.payment_info.qr" :options="{ size: 200 }"></VueQrcode>
          <div  v-if="!dvm" style="text-align: center;margin-bottom: 10px;" class="mb-3"> 
            <div target="_blank_" class="downloadButton" @click="downloadImage('https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + payment.payment_info.qr + '&choe=UTF-8')">Download</div>
          </div>
          <div class="mainContentDetail" v-if="!dvm">
    <h2 style="text-align: left;">How to Pay</h2>
    <ul style="font-size: 11px;text-align: left;">
      <li>a. Download the QRIS code above.</li>
      <li> b. Open the QRIS method on your payment application (m-Banking, Gopay, OVO, Shopee Pay, or DANA).</li>
      <li>c. Import the QR code from your gallery dan pay.</li>
    </ul>
  </div>
        </div>
        
        <div v-if="payment.payment_info &&  payment.payment_info.qr"
          class="footerButton"> 
            <button data-v-0b1d74b7="" @click="backHome()" class="buttonPrimary footerButton"
              style="width: 100%;">Finish</button> 
        </div>

        <div v-if="payment.payment_info && !payment.payment_info.qr && appcode != 'IPERFORM' && payment.status == 'wait' && payment.payment_info" class="footerButton">
          <a v-bind:href="payment.payment_info.redirect_url_app"><button data-v-0b1d74b7="" class="buttonPrimary footerButton" style="width: 100%;">Open {{payment.payment_method}}</button></a>
        </div>
      </div>
      <div v-else>
        <div style="text-align:center;">
              <img src="@/assets/loading.gif">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueQrcode from '@xkeshi/vue-qrcode';

  export default {
    name: 'TransactionDetail',
    components: {
      VueQrcode
    },
    data() {
      return {
        timeSecon : 0,
        timeMinut : 5,
        trxid: undefined,
        payment: undefined,
        special: undefined,
        loading: false,
        ovo: false,
        timer:undefined,
        appcode:process.env.VUE_APP_LIFFID
      }
    },
    watch: {
    '$root.liffCreated': function(value){
        console.log('watch liffcreated',value);
        if (value) {
          if (!this.payment) {
            this.load()
          }
        }
      }
    },
    beforeDestroy() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    methods: {
      async downloadImage(imageUrl) {
        const blob = await (await fetch(imageUrl)).blob();
        const url = URL.createObjectURL(blob);
        Object.assign(document.createElement('a'), { href: url, download: this.payment.trxid + 'payment.jpg' })
          .click();
        URL.revokeObjectURL(url);
      },
      printStruk(){ 
        
        var data = {
                      "title": "Airport Paid Service",
                      "ordercode": this.trxid,
                      "receipttype": "payment",
                      "time": "",
                      "fields": this.payment.info,
                      "fields2": null,
                      "terminal": {
                        "code": "",
                        "address": ""
                      },
                      "receipt_info_footer" : this.payment.product.code ==  "CARRENT"  ? "Show this receipt to the officer at the booth" : '',
                      "cs": "",
                      "prefix_ean": "",
                      "isShowBarcode": false,
                      "isReceipt": false,
                      "datetime": ""
                    }
        if(this.dvm){
          fetch(this.URLLOCAL + '/print/print.php', {
          method: 'POST',
          mode: 'cors',
          //headers: headers,
          body: JSON.stringify(data)
        })
          .then(response => response.json())
        }
      },
      backHome() { 
          window.location.href = '/'
      
      },
      back() {
        window.location.reload();
      },
       
      load(){
        if (this.loading) {
          return
        }

        if(this.timeSecon < 0){
          this.timeMinut = this.timeMinut - 1;
          this.timeSecon = 55;
        }

        this.loading = true
        let self = this
        this.$root.elGet('/trx/status/' + this.trxid)
            .then(data => {
              this.loading = false 
              if (data.ec == 200) {
                this.payment = data.data 

                if (this.payment.status == 'wait') {
                  if (this.payment.payment_method == 'OVO') {
                    this.ovo = true
                  }
                  self.timer = setTimeout(function() {
                    self.timeSecon = self.timeSecon-5;
                    self.load();
                  }, 5000)
                }
                if (this.payment.info) {
                  if (self.dvm && (self.payment.status == "done" || self.payment.status == "paid")) { 
                    self.printStruk();
                  }

                  if (this.payment.info.length > 0) {
                    if (this.payment.info[0].special) {
                      this.special = this.payment.info.shift()
                    }
                  }
                }
              } else {
                this.$root.elDialog(data.message, function() {
                  //window.location.href = '/' parman
                })
              }
            });
      }
    },
    mounted(){
      let params = this.$root.parseQueryPath(this.$root.searchRoute)
      console.log('param',params,this.$root.searchRoute)
      this.trxid = params.trxid;
      if (!this.trxid) {
        return;
      }
      localStorage.trxid = undefined
      console.log('trxdetail.mounted',this.$root.liffCreated)
     
      if (this.data) {
        this.payment = this.data;
         
      } else if (this.$root.liffCreated){
        this.load()
      }
  //  var self = this;
    
    
 
 
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.downloadButton{
  color: #42b983;
  text-decoration: underline;
}
  a{
    color: rgb(16 161 161);
  }
  
  .dote{
    border-top: 1px dashed;
  }
  .rowDataSpace{
        margin: 10px 0;
  }
  .titleDataDetail{
    margin-top: 43px;
    border-left: 3px solid rgb(28 184 184);
    padding-left: 6px;
  }

  .clear{
    clear: both;
  }
  .leftData {
      float: left;
  }
  .rightData{
    float: right;
  }

  .rowData{
    padding: 10px 0;
  }

  .mainContentDetail{
    padding: 10px 20px;
    font-size: 12px;
    color: rgb(148 148 148);
  }

  .mainContentDetail b.title{
      font-size: 16px;
  }
  .mainContentDetail b{
        color:#000;
  }
  .imgSuccess{
    width: 27px;
    margin: -3px;
    margin-right: 0px;
  }
  

  .noteHelp{
    font-weight: bold;
    color:rgb(16 161 161);
    font-size: 13px;
    margin-top: 5px;  
  }

  .success{
    background: #F7FBFB;
    padding: 10px;
    color:rgb(16 161 161);
  }

  .wait {
    background: #F5F5F5;
    padding: 10px;
    color: #333;
  }
  .failed {
  color:#D23439;
  background: #FDF8F8; 
  padding: 10px;
  }

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .detail-header {
    text-align:center;
    font-weight: bold;
    font-size: 30px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
  }

  .total {
    color:#333;
    font-size: 14px;
    font-weight: bold;
  }

  .special {
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
    height: 3em;
  }
  .special-value {
    font-size: 1.5em;
    color:#4A9F9F;
  }
  .special-label {
    font-size: 0.75em;
  }
  .footerButton {
    text-align: center;
  }
</style>
