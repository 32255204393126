<template>
  <Layout :hasBack="true" title="Pulsa"   @onBack="childBack">
    <div style="margin-top:5vmin;">
      <h4>
        No. Handphone
      </h4>
    </div>
    <input  @focus="onFocus('customerid')"  on-click="showKey()" class="input-customerid" type="number" pattern="\d*" v-model.trim="customerid" @input="onchange"  
      :style="{ backgroundImage: 'url(' + operator_img + ')' }" placeholder="Masukkan Nomor HP" ref="inputcustomerid"
      />
      <i v-if="operator_img"><img src="@/assets/ic-clear.svg"  class="clearButton" @click="clearCusId()" /></i>

    <div class="error" v-if="errormessage">{{errormessage}}</div>
    <div class="ul-products">
        <div v-for="d in denoms" :key="d.denom" v-bind:class="{ 'li-product-active': denom && denom.id == d.id,'li-product-inactive':d.status == 2}" @click="choose(d)" class="li-product">
          <div class="product-denom">{{d.quota}}</div>
          <div class="product-price">Rp. {{formatCurrency(d.price)}}</div>
        </div>
        <div v-if="denoms.length % 2 == 1" style="width: 40%;margin: 4pt;">
        </div>
    </div>
    <!-- <keypad @setkeydata="getkey" @close="isshowkeypad = false;onchange()" v-if="isshowkeypad"></keypad> -->

  </Layout>
</template>

<script>
//import keypad from './keypad.vue'
import MyMixin from '../mixins/mixin'
import PulsaMixin from '../mixins/pulsa'
import Layout from './Layout.vue'
export default {
  name: 'Pulsa',
  components: { 
    Layout
    //, keypad
  },
  mixins: [MyMixin, PulsaMixin],
  props: ['defaultcustomerid'],
  data() {
    return {
      isshowkeypad : false,
      message:'',
      modelSelected : '',
      product:undefined,
      pulsa: undefined
    }
  },
  methods: {
    onFocus(model) {
      this.modelSelected = model;
      this.isshowkeypad = true;
    }, 
    getkey(inputed) {
      this.inputed = inputed;
      this[this.modelSelected] = inputed.text;

    },
    clearCusId(){
      this.customerid = '';
      this.onchange();
    }
  },
  mounted() {
    this.product = this.loadObject('product')
    this.pulsa = this.product.products
    document.title = "Pulsa"
    this.$refs.inputcustomerid.focus()
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ul-products {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}
.li-product {
    width: 40%;
    margin: 4pt 4pt 4pt 4pt;
    font-weight: bold;
    display: inline-block;
    background: #49C8F5;
    border-radius: 4px;
    
}
.li-product-active {
  background: #0769B3;
}

.product-denom {
  font-size: 24px;
  margin: 10pt 8pt 30pt 8pt;
  color:#fff;
}
.product-price {
  font-size: 14px;
  margin: 1pt 30pt 4pt 8pt;
  color:#fff;
}

</style>
