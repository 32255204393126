<template>
    <div>
        <Layout :hasBack="true" :title="product ? product.name : ''" :csNumber="cs" @onBack="childBack">

            <div v-if="product" style="margin-top:20px;margin-botton:20px">
                <div class="error" v-if="errormessage">{{ errormessage }}</div>
                <div v-if="state == 0">
                    <img v-bind:src="product.icon" style="float:left" />
                    <div style="margin-left:40pt">{{ item.description }}
                    </div>
                    <div style="margin-left:40pt">
                        {{ product.params.desc }}
                    </div>
                </div>
                <div v-if="state == 1">
                    <div v-if="!selectedLocation"> 
                        <div class="formInput"  v-for="data in product.params" :key="data.label" >
                             
                            <label>{{ data.label }}</label>

                            <!-- 1=Text Input 2=Date 3=Time 4=Number 5=Text Area 6=Checkbox 7=Option 8=Email -->
                            <Datepicker v-if="data.type == 2" type="date" valueType="format" format="YYYY-MM-DD"
                                input-class="mx-input2" >
                            </Datepicker>
                            
                            <Datepicker v-if="data.type == 3"  type="time" :time-picker-options="timeFormat"
                                    valueType="format" format="HH:mm" input-class="mx-input2" defa></Datepicker>

                            <input v-if="data.type == 4" type="number" class="input-customerid" />
                            <input v-if="data.type == 1" type="number" class="input-customerid" />
                            <input v-if="data.type == 8" type="email" class="input-customerid" />

                        </div> 
                    </div>

                     
                   

                </div>
                <div v-if="state == 2">
                    <h2>Detail Contact</h2>
                    <div class="formInput" v-if="this.params.fields.name != 0">
                        <label>Name</label>
                        <input v-model.trim="name" class="input-customerid" />
                    </div>
                    <div class="formInput" v-if="this.params.fields.email != 0">
                        <label>Email</label>
                        <input v-model.trim="customerid" class="input-customerid" type="email" />
                        <div><i>Use a valid email for sending e-ticket</i></div>
                    </div>
                    <div class="formInput" v-if="this.params.fields.phone != 0">
                        <label>Phone</label>
                        <input v-model.trim="phone" class="input-customerid" type="number" pattern="\d*" />
                        <div><i>Use a valid phone number for further confirmation</i></div>
                    </div>
                </div>

                <div v-if="state == 3" class="confirmation">
                    <h3>Detail Reservation</h3>
                    <div class="detail">
                        <div v-for="(info, index) in confirm.info" :key="info.key">
                            <div v-if="index < detail_size && info.value">
                                <span>{{ info.label }}</span>
                                <span style="float:right;">{{ info.value }}</span>
                            </div>
                        </div>
                        <div v-if="detail_size < confirm.info.length" @click="full" class="full">
                            <span>more</span>
                            <span style="float:right;"><img src="@/assets/ic_down.png" /></span>
                        </div>
                    </div>

                    <div style="margin-top:20px" class="sub_total">
                        <span>Price</span>
                        <span style="float:right;">Rp {{ formatCurrency(confirm.amount) }}</span>
                    </div>

                    <div class="sub_total">
                        <span>Admin Fee</span>
                        <span style="float:right;">Rp {{ formatCurrency(confirm.admin_fee) }}</span>
                    </div>

                    <div class="sub_total" v-if="confirm.fee > 0">
                        <span>Admin Fee(Payment) </span>
                        <span style="float:right;">Rp {{ formatCurrency(confirm.fee) }}</span>
                    </div>
                    <div class="sub_total" v-if="confirm.discount > 0">
                        <span>Discount</span>
                        <span style="float:right;">- Rp {{ formatCurrency(confirm.discount) }}</span>
                    </div>

                    <div class="total">
                        <span>Total Price</span>
                        <span style="float:right;">Rp {{ formatCurrency(confirm.price) }}</span>
                    </div>

                    <div style="margin:20px">
                        <span style="color: rgb(16 161 161);text-decoration: underline;" @click="skShow()">
                            I agree with the Terms and Conditions</span>
                    </div>
                </div>

                <div class="footerButton">
                    <button class="buttonPrimary" style="width:80%" @click="next()">{{ next_label }}</button>
                </div>

                <div v-if="skOrder" class="modal-mask" @click="skOrder = false">
                    <!-- Modal content -->
                    <div class="modal-content" v-on:click.stop="nothing()">
                        <div class="modal-container">
                            <div class="modal-body mainContent">
                                <div>
                                    <div class="text-center titlePayment">
                                        <img src="@/assets/ic_back.png" style="float:left;width:20px;height:20px"
                                            @click="skOrder = false">

                                        <b>Term and Condition</b>
                                    </div>
                                </div>
                                <div class="skContent" v-html="params.toc">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        <!-- <keypad @setkeydata="getkey" @close="isshowkeypad = false" v-if="isshowkeypad"></keypad> -->
        <!-- <keypadfull></keypadfull>  -->
    </div>
</template>

<script>
import MyMixin from '../mixins/mixin'
import Layout from './Layout.vue'
//import keypad from './keypad.vue'
//import keypadfull from './keypadfull.vue'


import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';

export default {
    name: 'product',
    components: {
        Layout,
        // keypad,
        //keypadfull,
        Datepicker
    },
    mixins: [MyMixin],
    props: [],
    data() {
        return {
            timeDefault: '01:00',
            timeFormat: {
                start: '00:00',
                step: '00:15',
                end: '23:45',
                format: 'HH:mm'
            },
            baggage: 0,
            resultdestination: [],
            filterdestination: '',
            modelSelected: '',
            inputed: {
                text: '',
                num: ''
            },
            state: 0,
            product: null,
            cs: null,
            params: {},
            locations: [],
            destinations: [

            ],
            item: {},
            filterdestinationText: '',
            errormessage: null,
            customerid: null,
            confirm: null,
            meeting_point: '',
            next_label: 'CHOOSE',
            destination: 0,
            date: null,
            time: '13:00',
            name: null,
            phone: null,
            now: 0,
            detail_size: 4,
            skOrder: false,
            selectedLocation: null,
            isshowkeypad: false
        }
    },
    methods: {
        // changeValueMeeting(){
        //     this.meeting_point = '1';
        // },
        getPriceTime(info) {
            let rt = ' Rp. ';
            let time;
            let special = false;
            if (info.special_time) {
                time = info.special_time.split("-");
                if (time[0] && time[1]) {
                    const today = new Date();
                    const hour = today.getHours();
                    if (hour >= time[0] && hour <= time[1]) {
                        rt = rt + this.formatrupiah(info.special_price);
                        special = true;
                    }
                }
            }
            if (!special) {
                rt = rt + this.formatrupiah(info.price);
            }

            return rt;
        },
        formatrupiah(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")


        },
        chooseDes(data, text) {
            this.destination = data;
            this.filterdestinationText = text;

            this.resultdestination = [];
        },
        getdestination() {
            let vm = this;
            var data;
            console.log('masuk sini');
            if (this.filterdestinationText.length > 4) {
                console.log(vm.filterdestinationText);
                data = vm.product.areas.filter(car => {
                    return car.label.toLowerCase().indexOf(vm.filterdestinationText.toLowerCase()) != -1
                })
            }


            this.resultdestination = data;

        },
        onFocus(model) {
            this.modelSelected = model;
            this.isshowkeypad = true;
        },
        getkey(inputed) {
            this.inputed = inputed;
            this[this.modelSelected] = inputed.text;

        },
        showKey() {

        },
        childBack() {
            if (this.state <= 0) {
                return window.history.back()
            }
            this.state = this.state - 1
            return true
        },
        disabledDate(date,/*currentValue*/) {
            let d = parseInt(date.getTime() / (1000 * 60 * 60 * 24)) + 2
            return d <= this.now
        },
        next() {
            if (this.state == 0) {
                this.state = 1
                this.next_label = 'NEXT'
            } else if (1 == this.state) {
                if (!this.selectedLocation) {
                    let m = this.$moment(this.date + ' ' + this.time)
                    var duration = this.$moment.duration(m.diff());
                    let mi = duration.asMinutes()
                    if (mi < 120 && !this.dvm) {
                        this.errormessage = 'Order at least 120 minutes from the current time'
                        return;
                    }
                }
                if (!this.meeting_point || this.meeting_point.length == 0) {
                    this.errormessage = 'Select Meeting point'
                    return;
                }


                this.errormessage = '';
                this.state = 2
                this.next_label = 'NEXT'
                if (this.params.fields.name == 0 && this.params.fields.email == 0 && this.params.fields.phone == 0) {
                    this.next_label = 'NEXT'
                    this.inquiry()
                }
            } else if (2 == this.state) {
                if (this.params.fields.name == 2) {
                    if (!this.name || this.name == '') {
                        this.errormessage = 'Please fill the name and email'
                        return;
                    }
                }
                if (this.params.fields.email == 2) {
                    if (!this.customerid) {
                        this.errormessage = 'Please fill the name and email'
                        return;
                    }
                    if (!this.validateEmail(this.customerid)) {
                        this.errormessage = 'Invalid Email address'
                    }
                }
                if (this.params.fields.phone == 2) {
                    if (this.phone && this.phone.length > 0) {
                        if (this.phone.length < 10) {
                            this.errormessage = 'Invalid phone number'
                            return;
                        }
                    }
                }
                this.next_label = 'NEXT'
                this.inquiry()
            } else if (this.state == 3) {
                this.next_label = 'PAYMENT'
                this.submit()
            }
        },
        validateEmail(email) {
            if (email.lenth == 0) {
                return false
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        inquiry() {
            this.errormessage = '';
            let customerid = this.customerid.trim()
            if (customerid.length == 0 || this.baggage < 1) {
                return
            }

            let self = this
            this.$root.elLoadings(true, 'Loading')
            this.$root.elPost('/trx/inquiry', {
                customerid: customerid,
                product_id: this.selected.id,
                product_code: this.product.code,
                data: {
                    car: this.baggage,
                    meeting_point: this.meeting_point,
                    date: this.date,
                    time: this.time,
                    destination: this.destination,
                    from: this.selectedLocation ? 3 : 1,
                    additional: {
                        name: this.name,
                        phone: this.phone
                    }
                }
            })
                .then(data => {
                    this.$root.elLoadings(false)
                    if (data.ec == 200) {
                        self.confirm = data.data
                        self.state = 3
                        self.next_label = 'Continue to Payment'
                    } else {
                        this.errormessage = data.message
                        //self.$root.elDialog(data.message)
                    }
                });
        },
        full() {
            this.detail_size = this.confirm.info.length
        },
        submit() {
            this.errormessage = '';
            this.confirm.label_customerid = this.product.label
            this.confirm.email = this.customerid
            this.onConfirm(this.confirm)
        },
        skShow() {
            this.skOrder = true
        }
    },
    mounted() {
        this.product = this.loadObject('product');
        console.log('carrent.product', this.product)

        this.params = this.product.params
        this.cs = this.product.params.cs
        document.title = this.product.name
        if (localStorage.defaultcustomerid) {
            this.customerid = localStorage.defaultcustomerid
            this.onchange()
        }
        if (this.product && this.product.item) {
            this.item = this.product.item[0]
            this.state = 0
            this.selected = this.item
        }

        let nextT = this.$moment()
        let div = 15;
        this.now = parseInt(nextT.unix() / (60 * 60 * 24))
        if (this.dvm) {
            nextT.add('0', 'hour');
        } else {
            nextT.add('2', 'hour');
        }

        this.date = nextT.format("YYYY-MM-DD")
        var m = nextT.format("mm")
        m = parseInt(m / div) * div + div
        if (m == 60) {
            m = '00'
            nextT.add('1', 'hour')
        }
        //if (m < 10) m = '0' + m
        this.time = nextT.format("HH") + ':' + m

        if (!this.params.max_destination) {
            this.params.max_destination = 5
        }
        for (var i = 1; i <= this.params.max_destination; i++) {
            this.destinations.push(i)
        }
        this.locations = []
        let self = this
        this.product.carrent_meetings.forEach(v => {
            if (v.code == self.product.param1) {
                this.meeting_point = v.name
                this.selectedLocation = v.name
            }
            self.locations.push(v.name)
        })


        this.product.areas;
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.selectmeeting select:required:invalid {
    color: #c9c9c9;
}

.selectmeeting option[value=""][disabled] {
    display: none;
}

.selectmeeting option {
    color: black;
}


.mx-datepicker {
    width: 100%;
}

.mx-input2 {
    background: #f5f5f5;
    color: #111;
    width: 100%;
    font-weight: bold;
    height: 48px;
    font-size: 12pt;
    padding-left: 20px;
    padding-right: 80px;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 64px;
    background-repeat: no-repeat;

    background-size: 32px;
    background-position: 90% 50%;
}
</style>
<style scoped>
li {
    height: 20px;
    margin-left: 0px;
}

.vs__dropdown-option--highlight {
    width: 100%;
    margin-left: 0px;
}
</style>
<style scoped>
h3 {
    border-left: 0.25em solid #4A9F9F;
    padding-left: 0em;
}

img {
    width: 30pt;
    height: 30pt;
}

h4 {
    font-size: 16pt;
}

label {
    white-space: pre;
    font-weight: bold;
    margin-bottom: 10px;
}

.formInput {
    padding: 10px;
}

.item {
    min-height: 48pt;
    font-size: 13pt;
    padding: 2px;
    border-bottom: solid 1pt #F5F5F5;
    display: flex;
    align-items: center;
}

.item:active {
    background: #F5F5F5;
}

.icon {
    min-width: 36px;
    margin-right: 16px;
}

.confirmation {
    margin: 0.1em;
    padding: 0.1em;
}

.detail {
    color: #949494;
}

.total {
    margin-top: 0.3em;
    padding-top: 0.2em;
    border-top: 2px dashed #C4C4C4;
    font-weight: bold;
}

.full {
    color: #4A9F9F;
    font-weight: bold;
    padding: 0 0 0.25em;
}

.footerButton {
    text-align: center;
}

li {
    width: 100%;
    text-align: left;
}

ul {
    padding: 5px;
    margin: 0;
}

.skContent {}
</style>
