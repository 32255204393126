export default {
    created () {
      //this.hello()
    },
    data () {
        return {
            client: null,
            profile: {
                userId: '',
                displayName: ''
              }
        }
    },
    methods: {
        formatCurrency(number) {
            //return number.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&.');
            let x = 0
            let n = 0
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return parseFloat(number).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
        },
        onConfirm(confirm) {
            console.log('onConfirm', confirm)
            this.$root.confirmationData = confirm
            this.$root.isConfirm = true
        }
    }
  }