<template>
  <Layout :hasBack="true" :title="product?product.name:''"   @onBack="childBack">
    <div style="margin-top:5vmin;">
      <h4 v-if="product && $root.isPageOffset == 0">
        {{product.sub_label}}
      </h4>
      <h4 v-if="product && $root.isPageOffset == 1">
          {{product.label}}
          <img v-if="product.code != 'PASCA'" :src="icon_provider" class="imageOperator" style="float:right">
      </h4>
    </div>
    <input class="input-customerid" v-if="!product || product.code != 'BPJS' || step2"  v-model.trim="pattern" @input="onChangePattern"
          ref="inputcustomerid" v-bind:placeholder="placeholder" v-bind:type="inputType" v-bind:pattern="inputPattern"
      />
    <i v-if="pattern.length > 1"><img src="@/assets/ic-clear.svg"  class="clearButton" @click="clearCusId()" /></i>
    <div v-if="$root.isPageOffset == 0">
      <div v-for="item in items" :key="item.code">
        <div @click="select(item)" class="item" v-bind:class="{'li-product-inactive':item.status != 1}">
          <img :src="item.icon && item.icon.length>0 ? item.icon : product.icon" class="icon"> {{item.name}}
          <img style="margin-left: auto;order: 2;" src="@/assets/ic_arrow_right.svg">
        </div>
      </div>
    </div>
    <div v-if="$root.isPageOffset == 1">
      <div class="error" v-if="errormessage">{{errormessage}}</div>
      <div v-if="confirm" class="confirmation">
          <h3>Info Tagihan</h3>
          <div class="detail">
            <div v-for="(info,index) in confirm.info" :key="info.key">
              <div v-if="index < detail_size">
                <span >{{info.label}}</span>
                <span style="float:right;">{{info.value}}</span>
              </div>
            </div>
            <div v-if="detail_size < confirm.info.length" @click="full" class="full">
              <span >Selengkapnya</span>
              <span style="float:right;"><img src="@/assets/ic_down.png"/></span>
            </div>
          </div>
          
            <div style="margin-top:30px" class="sub_total">
              <span >Tagihan</span>
              <span style="float:right;">Rp {{formatCurrency(confirm.amount)}}</span>
            </div>

            <div class="sub_total">
              <span >Admin Fee</span>
              <span style="float:right;">Rp {{formatCurrency(confirm.admin_fee)}}</span>
            </div>

            <div class="sub_total" v-if="confirm.fee>0">
              <span >Admin Fee(Payment) </span>
              <span style="float:right;">Rp {{formatCurrency(confirm.fee)}}</span>
            </div>
            <div class="sub_total" v-if="confirm.discount>0">
              <span >Discount</span>
              <span style="float:right;">- Rp {{formatCurrency(confirm.discount)}}</span>
            </div>

            <div class="total">
              <span >Total Tagihan</span>
              <span style="float:right;">Rp {{formatCurrency(confirm.price)}}</span>
            </div>
      </div>
      <div class="footerButton">
        <button class="buttonPrimary" style="width:80%" @click="next()" :disabled="pattern.length<5">{{next_label}}</button>
      </div>
    </div>
  </Layout>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import MyMixin from '../mixins/mixin'
import PulsaMixin from '../mixins/pulsa'
import Layout from './Layout.vue'

export default {
  name: 'Bill',
  components: { 
    Layout
  },
  mixins: [MyMixin, PulsaMixin],
  props: [],
  data() {
    return {
      step2: false,
      confirm:null,
      selected: null,
      options: [],
      state: 0,
      icon_provider: '',
      next_label: 'Berikutnya',
      product:undefined,
      defaultcustomerid: '',
      detail_size: 4,
      errormessage:'',
      items: [],
      pattern:'',
      oldpattern:'',
      placeHolder:'Contoh:1234567890',
      placeHolder1:'Cari provider/cluster',
    }
  },
  computed: {
   placeholder() {
      return this.$root.isPageOffset == 0 ? this.placeHolder1 : this.placeHolder
   },
   inputType() {
     return this.$root.isPageOffset == 0 ? 'text' : 'numeric'
   },
   inputPattern() {
     return this.$root.isPageOffset == 0 ? '' : '\\d*'
   }
  },
  methods: {
    clearCusId(){
      this.customerid = '';
      this.pattern = '';
      this.confirm = null
      this.state = 0
      this.next_label = 'Berikutnya'
      //this.onchange();
       var self = this;
       setTimeout(function(){      
         self.$el.getElementsByClassName("input-customerid")[0].focus();
      }, 200);
    },
    full(){
      this.detail_size = this.confirm.info.length
    },
    select(item) {
      if (item.status != 1) {
        return
      }
      this.step2 = true
      this.selected = item
      if (this.selected.icon && this.selected.icon.length > 0) {
          this.icon_provider = this.selected.icon
        } else {
          this.icon_provider = this.product.icon
        }
      this.$root.isPageOffset = 1
      this.pattern = ''
      document.title = this.selected.name
      window.history.pushState(
                  null,
                  this,
                  '#page1'
                )
    },
    onChangePattern() {
      if (this.$root.isPageOffset == 1) {
        this.customerid =this.pattern
        this.onchange()
        return
      }
      if (this.oldpattern == this.pattern) {
        return
      }
      let p = this.pattern.toLowerCase()
      var i;
      var from;
      if (!this.pattern || this.pattern == '') {
        this.items = this.product.item.slice()
      } else if (this.oldpattern.length <= this.pattern.length) {
        from = this.items
      } else {
        from = this.product.item  
      }
      if (from) {
        this.items = []
        for(i = 0; i < from.length;i++) {
          let ii = from[i]
          if (ii.name && ii.name.toLowerCase().includes(p)) {
            this.items.push(ii)
          }
        }
      }
      this.oldpattern = this.pattern
    },
    next(){
      
      if (this.state == 0) {
        this.inquiry()
      } else if (this.state == 1) {
        this.submit()
      }
    },
    inquiry() {
      this.errormessage = '';
      let customerid = this.customerid.trim()
      if (customerid.length ==0 ) {
        return
      }

      let self = this
      this.$root.elLoadings(true, 'Loading')
      this.$root.elPost('/trx/inquiry',{
          customerid: customerid,
          product_id: this.selected.id,
          product_code: this.product.code
        })
          .then(data => {
            this.$root.elLoadings(false)
            if (data.ec == 200) {
              self.confirm = data.data
              self.state = 1
              self.next_label = 'Lanjut Bayar'
            } else {
              this.errormessage = data.message
              //self.$root.elDialog(data.message)
            }
          });
    },
    submit() {
      this.errormessage = '';
      this.confirm.label_customerid = this.product.label
      this.onConfirm(this.confirm)
    }
  },
  mounted() {

    this.product = this.loadObject('product')
    console.log('bill.product', this.product)
    document.title = this.product.name
    if (localStorage.defaultcustomerid) {
      this.customerid = localStorage.defaultcustomerid
      this.onchange()
    }
    if (this.product) {
      if (this.product.placeHolder1) {
        this.placeHolder1 = this.product.placeHolder1
      }
      if (this.product.placeHolder2) {
        this.placeHolder = this.product.placeHolder2
      }
      if (this.product.code == 'PASCA' || !this.product.item || this.product.item.length == 1) {
        this.selected = this.product.item[0]
        if (this.selected.icon && this.selected.icon.length > 0) {
          this.icon_provider = this.selected.icon
        } else {
          this.icon_provider = this.product.icon
        }
        this.$root.isPageOffset = 1
      } else {
        this.$root.isPageOffset = 0
        this.items = this.product.item.slice()
      }
      this.$refs.inputcustomerid.focus()
      
    }

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  border-left: 0.25em solid #4A9F9F;
  padding-left: 0em;
}
h4 {
  font-size:16pt;
}
.item {
  min-height: 48pt;
  font-size:13pt;
  padding: 2px;
  border-bottom:solid 1pt #F5F5F5;
  display: flex;
  align-items: center;
}
.item:active {
  background: #F5F5F5;
}
.icon {
  min-width: 36px;
  margin-right: 16px;
}
.confirmation {
  margin: 0.1em ;
  padding: 0.1em;
}
.detail {
  color:#949494;
}
.total {
  margin-top: 0.3em;
  padding-top: 0.2em;
  border-top: 2px dashed #C4C4C4;
  font-weight: bold;
}

.full {
  color:#4A9F9F;
  font-weight: bold;
  padding: 0 0 0.25em;
}
.footerButton {
  text-align: center;
}
</style>
