<template>
    <div>
        <Layout :hasBack="true" :title="'Print Struk'" >

            <div class="formInput">
                <label>Id Transaction</label>
                <div>
                    <input type="text" class="mx-input2" v-model="idtrx">
                </div> 
            </div>
            <button class="buttonPrimary" style="width:100%" @click="next()">Print Struk</button>

        </Layout> 
    </div>
</template>

<script>
import MyMixin from '../mixins/mixin'
import Layout from './Layout.vue'
//import keypad from './keypad.vue'
//import keypadfull from './keypadfull.vue'

 
import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';

export default {
    name: 'carrent',
    components: {
        Layout, 
    },
    mixins: [MyMixin],
    props: [],
    data() {
        return {
            idtrx: '',
        }
    },
    methods: {
        
        next() { 
            this.go('/detail?trxid=' + this.idtrx, true)
        },

    },
    mounted() {
         
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style> 

</style>
<style scoped>
 
.mx-input2 {
    background: #f5f5f5;
    color: #111;
    width: 100%;
    font-weight: bold;
    height: 48px;
    font-size: 12pt;
    padding-left: 20px;
    padding-right: 80px;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 64px;
    background-repeat: no-repeat;

    background-size: 32px;
    background-position: 90% 50%;
}
li {
    height: 20px;
    margin-left: 0px;
}

 
h3 {
    border-left: 0.25em solid #4A9F9F;
    padding-left: 0em;
}

img {
    width: 30pt;
    height: 30pt;
}

h4 {
    font-size: 16pt;
}

label {
    white-space: pre;
    font-weight: bold;
    margin-bottom: 10px;
}

.formInput {
    padding: 10px;
}

li {
    width: 100%;
    text-align: left;
}

ul {
    padding: 5px;
    margin: 0;
}
 
</style>
