<template>
  <div>
    <Layout
      :hasBack="true"
      :title="product ? product.name : ''"
      :csNumber="cs"
      @onBack="childBack"
    >
      <div v-if="product" style="margin-top: 20px; margin-botton: 20px">
        <div class="error" v-if="errormessage">{{ errormessage }}</div>
        <div v-if="state == 0">
          <img v-bind:src="product.icon" style="float: left" /> 
          <div style="margin-left: 40pt;text-align: justify;">
            {{ product.params.desc }}
          </div>
        </div>
        <div v-if="state == 1">
          <div v-if="!selectedLocation">
            <div class="formInput" v-if="!dvm">
              <label>Meeting Date</label>
              <Datepicker
                v-model="date"
                type="date"
                valueType="format"
                format="YYYY-MM-DD"
                input-class="mx-input2"
                :disabled-date="disabledDate"
              >
              </Datepicker>
            </div>
            <div class="formInput" v-if="!dvm">
              <label>Meeting Time</label>

              <Datepicker
                v-model="time"
                type="time"
                :time-picker-options="timeFormat"
                valueType="format"
                format="HH:mm"
                input-class="mx-input2"
                defa
              ></Datepicker>
              <div>
                <!-- <i>Order at least 90 minutes from current time</i> -->
              </div>
            </div>
          </div>

          <div class="formInput">
            <label
              >Meeting Point
              <span class="text-danger" v-if="errorMeetingPoin">{{
                errorMeetingPoin
              }}</span></label
            >

            <div v-if="selectedLocation"><b></b></div>
            <div v-else class="selectmeeting">
              <select
                required
                id="locations"
                v-model="meeting_point"
                class="input-customerid"
              >
                <option v-if="!dvm" value="" class="disable" disabled selected>
                    Please Input Your Meeting Point
                </option>
                <option
                    v-bind:key="index"
                    v-for="(option, index) in locations"
                    v-bind:value="option"
                >
                    {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="formInput">
            <label>Destination</label>
            <input
              @keyup="getdestination($event)"
              placeholder="Please Input Your Destination (District/Ward/Subdistrict)"
              v-model.trim="filterdestinationText"
              class="input-customerid"
            />
            <ul v-for="(info, index) in resultdestination" :key="index">
              <li
                class="pointer"
                @click="chooseDes(info.id, info.label + getPriceTime(info))"
              >
                {{ info.label }} ({{ getPriceTime(info) }})
              </li>
            </ul>
            <span class="text-info">*Rates do not include toll & parking fee</span>
          </div>
          <div class="formInput">
            <label>Cars</label>
            <input
              type="number"
              v-model.trim="baggage"
              class="input-customerid"
            />
            <span class="text-info">Maximum capacity in one car is 5 people</span>
          </div>
        </div>
        <div v-if="state == 2">
          <h2>Detail Contact</h2>
          <div class="formInput" v-if="this.params.fields.name != 0">
            <label>Name</label>
            <input v-model.trim="name" class="input-customerid" />
          </div>
          <div class="formInput" v-if="this.params.fields.email != 0">
            <label>Email</label>
            <input
              v-model.trim="customerid"
              class="input-customerid"
              type="email"
            />
            <span class="text-info"> Use a valid email for sending e-ticket</span>
          </div>
          <div class="formInput" v-if="this.params.fields.phone != 0">
            <label>Phone</label>
            <input
              v-model.trim="phone"
              class="input-customerid"
              type="number"
              pattern="\d*"
            />
            <span class="text-info">Use a valid phone number for further confirmation</span>
          </div>
        </div>

        <div v-if="state == 3" class="confirmation">
          <h3>Detail Reservation</h3>
          <div class="detail">
            <div v-for="(info, index) in confirm.info" :key="info.key">
              <div v-if="index < detail_size && info.value">
                <span>{{ info.label }}</span>
                <span style="float: right;    text-align: justify;">{{ info.value }}</span>
              </div>
            </div>
            <div
              v-if="detail_size < confirm.info.length"
              @click="full"
              class="full"
            >
              <span>more</span>
              <span style="float: right"
                ><img src="@/assets/ic_down.png"
              /></span>
            </div>
          </div>

          <div style="margin-top: 30px" class="sub_total">
            <span>Price</span>
            <span style="float: right"
              >Rp {{ formatCurrency(confirm.amount) }}</span
            >
          </div>

          <div class="sub_total">
            <span>Admin Fee</span>
            <span style="float: right"
              >Rp {{ formatCurrency(confirm.admin_fee) }}</span
            >
          </div>

          <div class="sub_total" v-if="confirm.fee > 0">
            <span>Admin Fee(Payment) </span>
            <span style="float: right"
              >Rp {{ formatCurrency(confirm.fee) }}</span
            >
          </div>
          <div class="sub_total" v-if="confirm.discount > 0">
            <span>Discount</span>
            <span style="float: right"
              >- Rp {{ formatCurrency(confirm.discount) }}</span
            >
          </div>

          <div class="total">
            <span>Total Price</span>
            <span style="float: right"
              >Rp {{ formatCurrency(confirm.price) }}</span
            >
          </div>

          <div style="margin: 20px">
            <input type="checkbox" name="itc" value="1" v-model="itcvalue"> 
            <span
              style="color: rgb(16 161 161); text-decoration: underline"
              @click="skShow()"
            >
             
   I agree with the Terms and Conditions</span
            >
          </div>
        </div>

        <div class="footerButton">
          <button class="buttonPrimary" style="width: 80%" @click="next()">
            {{ next_label }}
          </button>
        </div>

        <div v-if="skOrder" class="modal-mask" @click="skOrder = false">
          <!-- Modal content -->
          <div class="modal-content" v-on:click.stop="nothing()">
            <div class="modal-container">
              <div class="modal-body mainContent">
                <div>
                  <div class="text-center titlePayment">
                    <img
                      src="@/assets/ic_back.png"
                      style="float: left; width: 20px; height: 20px"
                      @click="skOrder = false"
                    />

                    <b>Term and Condition</b>
                  </div>
                </div>
                <div class="skContent" v-html="params.toc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    <!-- <keypad @setkeydata="getkey" @close="isshowkeypad = false" v-if="isshowkeypad"></keypad> -->
    <!-- <keypadfull></keypadfull>  -->
  </div>
</template>

<script>
import MyMixin from "../mixins/mixin";
import Layout from "./Layout.vue";
//import keypad from './keypad.vue'
//import keypadfull from './keypadfull.vue'

import Datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue-select/dist/vue-select.css";

export default {
  name: "carrent",
  components: {
    Layout,
    // keypad,
    //keypadfull,
    Datepicker,
  },
  mixins: [MyMixin],
  props: [],
  data() {
    return {
      itcvalue : 0,
      errorMeetingPoin: "",
      timeDefault: "01:00",
      timeFormat: {
        start: "06:00",
        step: "00:15",
        end: "23:55",
        format: "HH:mm",
      },
      baggage: 0,
      resultdestination: [],
      filterdestination: "",
      modelSelected: "",
      inputed: {
        text: "",
        num: "",
      },
      state: 0,
      product: null,
      cs: null,
      params: {},
      locations: [],
      destinations: [],
      item: {},
      filterdestinationText: "",
      errormessage: null,
      customerid: null,
      confirm: null,
      meeting_point: "",
      next_label: "CHOOSE",
      destination: 0,
      date: null,
      time: "13:00",
      name: null,
      phone: null,
      now: 0,
      detail_size: 4,
      skOrder: false,
      selectedLocation: null,
      isshowkeypad: false,
    };
  },
  methods: {
    // changeValueMeeting(){
    //     this.meeting_point = '1';
    // },
    validateEmail(email) { 
      if (email.lenth == 0) {
        return false
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    getPriceTime(info) {
      let rt = " Rp. ";
      let time;
      let special = false;
      if (info.special_time) {
        time = info.special_time.split("-");
        if (time[0] && time[1]) {
          const today = new Date();
          const hour = today.getHours();
          if (hour >= time[0] && hour <= time[1]) {
            rt = rt + this.formatrupiah(info.special_price);
            special = true;
          }
        }
      }
      if (!special) {
        rt = rt + this.formatrupiah(info.price);
      }

      return rt;
    },
    formatrupiah(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    chooseDes(data, text) {
      this.destination = data;
      this.filterdestinationText = text;

      this.resultdestination = [];
    },
    getdestination() {
      this.errorMeetingPoin = "";

      let vm = this;
      var data;
      var datas = [];

      if (!this.meeting_point) {
        this.errorMeetingPoin = "Please Select Meeting Point";
        return false;
      }

      if (this.filterdestinationText.length > 2) {
        data = vm.product.areas.filter((ar) => {
          if (ar.price > 0) {
            return (
              ar.label
                .toLowerCase()
                .indexOf(vm.filterdestinationText.toLowerCase()) != -1
            );
          }
        });

        let metPoin = vm.product.carrent_meetings.filter((me) => {
          return (
            me.name.toLowerCase().indexOf(vm.meeting_point.toLowerCase()) != -1
          );
        })[0];

        var dataFilter = [];
        for (var d in data) { 
          if (data[d].meeting_poin == metPoin.id) {
            datas.push(data[d]);
            dataFilter.push(data[d].label); 
          } else if (data[d].meeting_poin == 0) { 
            if (!dataFilter.includes(data[d].label)) {
              datas.push(data[d]);
            }
          } 
        }
      }

      this.resultdestination = datas;
    },
    onFocus(model) {
      this.modelSelected = model;
      this.isshowkeypad = true;
    },
    getkey(inputed) {
      this.inputed = inputed;
      this[this.modelSelected] = inputed.text;
    },
    showKey() {},
    childBack() {
      if (this.state <= 0) {
        return window.history.back();
      }
      this.state = this.state - 1;
      return true;
    },
    disabledDate(date /*currentValue*/) {
      let d = parseInt(date.getTime() / (1000 * 60 * 60 * 24)) + 2;
      return d <= this.now;
    },
    next() {
      if (this.state == 0) {
        this.state = 1;
        this.next_label = "NEXT";
      } else if (1 == this.state) {
        if (!this.selectedLocation) {
          let m = this.$moment(this.date + " " + this.time);
          var duration = this.$moment.duration(m.diff());
          let mi = duration.asMinutes();
          if (mi < 30 && !this.dvm) {
            this.errormessage =
              "Order at least 30 minutes from the current time";
            return;
          }
        }
        

        

        if (!this.meeting_point || this.meeting_point.length == 0) {
          this.errormessage = "Select Meeting point";
          return;
        }

        if (!this.filterdestinationText || this.filterdestinationText.length == 0) {
          this.errormessage = "Select Destination";
          return;
        }

        if (!this.baggage || this.baggage.length == 0) {
          this.errormessage = "Car minimum 1";
          return;
        }

         

        this.errormessage = "";
        this.state = 2;
        this.next_label = "NEXT";
        if (
          this.params.fields.name == 0 &&
          this.params.fields.email == 0 &&
          this.params.fields.phone == 0
        ) {
          this.next_label = "NEXT";
          this.inquiry();
        }
      } else if (2 == this.state) {
        if (this.params.fields.name == 2) {
          if (!this.name || this.name == "") {
            this.errormessage = "Please fill the phone and email";
            return;
          }
        } 

          if (!this.customerid) {
            this.errormessage = "Please fill the phone and email";
            return;
          } 
          if (!this.validateEmail(this.customerid)) {
            this.errormessage = "Invalid Email address";
            return false;
          }
       
           
            if (this.phone) {
          if (this.phone.length < 10) {
            this.errormessage = 'Invalid phone number'
            return;
          }
        } else {
          this.errormessage = 'Invalid phone number'
          return;
        } 

        this.next_label = "NEXT";
        this.inquiry();
      } else if (this.state == 3) {
        if(this.itcvalue == 0){
          this.errormessage = 'Please check I agree with the Terms and Conditions'
          return false;
        }
        this.next_label = "PAYMENT";
        this.submit();
      }
    },
    
    inquiry() {
      this.errormessage = "";
      let customerid = this.customerid.trim(); 
      if (customerid.length == 0 || this.baggage < 1) {
        return;
      }
      
      let self = this;
      this.$root.elLoadings(true, "Loading");
      this.$root
        .elPost("/trx/inquiry", {
          customerid: customerid,
          product_id: this.selected.id,
          product_code: this.product.code,
          data: {
            carvendor: this.$root.carvendor,
            car: this.baggage,
            meeting_point: this.meeting_point,
            date: this.date,
            time: this.time,
            destination: this.destination,
            from: this.selectedLocation ? 3 : 1,
            additional: {
              name: this.name,
              phone: this.phone,
            },
          },
        })
        .then((data) => {
          this.$root.elLoadings(false);
          if (data.ec == 200) {
            self.confirm = data.data;
            self.state = 3;
            self.next_label = "Continue to Payment";
          } else {
            this.errormessage = data.message;
            //self.$root.elDialog(data.message)
          }
        });
    },
    full() {
      this.detail_size = this.confirm.info.length;
    },
    submit() {
      this.errormessage = "";
      this.confirm.label_customerid = this.product.label;
      this.confirm.email = this.customerid;
      this.confirm.carvendor = this.$root.carvendor;
      this.onConfirm(this.confirm);
    },
    skShow() {
      this.skOrder = true;
    },
  },
  mounted() {
     
    
 
    this.product = this.loadObject("product");
    console.log("carrent.product", this.product);

    this.params = this.product.params;
    this.cs = this.product.params.cs;
    document.title = this.product.name;
    if (localStorage.defaultcustomerid) {
      this.customerid = localStorage.defaultcustomerid;
      this.onchange();
    }
    if (this.product && this.product.item) {
      this.item = this.product.item[0];
      this.state = 0;
      this.selected = this.item;
    }

    let nextT = this.$moment();
    let div = 15;
    this.now = parseInt(nextT.unix() / (60 * 60 * 24));
    if (this.dvm) {
      nextT.add("0", "hour");
    } else {
      nextT.add("0.5", "hour");
    }

    this.date = nextT.format("YYYY-MM-DD");
    var m = nextT.format("mm");
    m = parseInt(m / div) * div + div;
    if (m == 60) {
      m = "00";
      nextT.add("1", "hour");
    }
    //if (m < 10) m = '0' + m
    this.time = nextT.format("HH") + ":" + m;

    if (!this.params.max_destination) {
      this.params.max_destination = 5;
    }
    for (var i = 1; i <= this.params.max_destination; i++) {
      this.destinations.push(i);
    }
    this.locations = [];
    let self = this;
    this.product.carrent_meetings.forEach((v) => {
      if (v.code == self.product.param1) {
        this.meeting_point = v.name;
        this.selectedLocation = v.name;
      }
      if (this.locationDvm) {
        if (v.name.includes(this.locationDvm)) {
          self.locations.push(v.name);
        }
      } else {
        self.locations.push(v.name);
      }
    }); 
    if(self.dvm){
            setTimeout(function () {
                self.meeting_point = self.locations[0];
            }, 2000);
    }

    this.product.areas;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .selectmeeting select:required:invalid {
    color: #c9c9c9;
  }
  .selectmeeting option[value=""][disabled] {
    display: none;
  }
  .selectmeeting option {
    color: black;
  }

  .mx-datepicker {
    width: 100%;
  }

  .mx-input2 {
    background: #f5f5f5;
    color: #111;
    width: 100%;
    font-weight: bold;
    height: 48px;
    font-size: 12pt;
    padding-left: 20px;
    padding-right: 80px;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 64px;
    background-repeat: no-repeat;

    background-size: 32px;
    background-position: 90% 50%;
  }
</style>
<style scoped>
  li {
    height: 20px;
    margin-left: 0px;
  }
  
  </style>
  <style scoped>
  h3 {
    border-left: 0.25em solid #4a9f9f;
    padding-left: 0em;
  }

  img {
    width: 30pt;
    height: 30pt;
  }

  h4 {
    font-size: 16pt;
  }

  label {
    white-space: pre;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .formInput {
    padding: 10px;
  }

  .item {
    min-height: 48pt;
    font-size: 13pt;
    padding: 2px;
    border-bottom: solid 1pt #f5f5f5;
    display: flex;
    align-items: center;
  }
  

  
  .confirmation {
    margin: 0.1em;
    padding: 0.1em;
  }

  .detail {
    color: #949494;
  }


  .total {
    margin-top: 0.3em;
    padding-top: 0.2em;
    border-top: 2px dashed #c4c4c4;
    font-weight: bold;
  }

  .full {
    color: #4a9f9f;
    font-weight: bold;
    padding: 0 0 0.25em;
  }

  .footerButton {
    text-align: center;
    margin-top: 3vmin;
  }
  li {
    width: 100% !important;
    text-align: left  !important;
    height: inherit !important;
    font-size: inherit !important;
  }
  ul {
    padding: 5px  !important;
    margin: 0  !important;
  }
  .skContent {
  }
</style>
