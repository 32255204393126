<template>
    <div>
        <Layout :hasBack="true" :title="product ? product.name : ''" :csNumber="cs" @onBack="childBack">
            <h1 class="error" v-if="errormessage" style="font-size: inherit;">{{ errormessage }}</h1>
            <div v-if="state == 0">
              <div class="ul-products" v-if="product && product.item">
                <div v-for="d in product.item" :key="d.id"  @click="choose(d)" class="li-product">
                  <div>
                    <div>
                      <img :src="d.icon">
                    </div>
                    <div>
                      <div class="product-denom">{{ d.name }} </div>
                      <div class="product-price">Rp. {{ formatCurrency(d.price) }}</div>
                    </div>
                    
                  </div>
                </div>
                <div v-if="product.item.length % 2 == 1" style="width: 40%;margin: 4pt;">
                </div> 
                
                <div v-if="htaOrder" class="modal-mask" @click="htaOrder = false">
                <!-- Modal content -->
                <div class="modal-content" v-on:click.stop="nothing()">
                  <div class="modal-container">
                    <div class="modal-body mainContent">
                      <div>
                        <div class="text-center titlePayment">
                          <img
                            src="@/assets/ic_back.png"
                            class="backsk"
                            @click="htaOrder = false"
                          />

                          <b>How To Apply The Voucher</b>
                        </div>
                      </div>
                      <div class="skContent" v-html="product.params.hta"></div>
                    </div>
                  </div>
                </div>
              </div>


              </div>
              <div class="noteProduct" @click="htaOrder = true">How To Apply The Voucher</div>
            </div>
            <div v-if="state == 1">
                <h2>Detail Contact</h2>
                
                <div class="formInput" >
                    <label>Email</label>
                    <input
                    v-model.trim="customerid"
                    class="input-customerid"
                    type="email"
                    />
                    <div><i>Use a valid email for sending e-ticket</i></div>
                </div>
                <div class="formInput">
                    <label>Phone</label>
                    <input
                    v-model.trim="phone"
                    class="input-customerid"
                    type="number"
                    pattern="\d*"
                    />
                    <div><i>Use a valid phone number for further confirmation</i></div>
                </div>
            </div>
            <div v-if="state == 2" class="confirmation">
              <h3>Detail Reservation</h3>
              <div class="detail">
                <div v-for="(info, index) in confirm.info" :key="info.key">
                  <div v-if="index < detail_size && info.value">
                    <span>{{ info.label }}</span>
                    <span style="float: right">{{ info.value }}</span>
                  </div>
                </div>
                <div
                  v-if="detail_size < confirm.info.length"
                  @click="full"
                  class="full"
                >
                  <span>more</span>
                  <span style="float: right"
                    ><img src="@/assets/ic_down.png"
                  /></span>
                </div>
              </div>

              <div style="margin-top: 30px" class="sub_total">
                <span>Price</span>
                <span style="float: right"
                  >Rp {{ formatCurrency(confirm.amount) }}</span
                >
              </div>

              <div class="sub_total">
                <span>Admin Fee</span>
                <span style="float: right"
                  >Rp {{ formatCurrency(confirm.admin_fee) }}</span
                >
              </div>

              <div class="sub_total" v-if="confirm.fee > 0">
                <span>Admin Fee(Payment) </span>
                <span style="float: right"
                  >Rp {{ formatCurrency(confirm.fee) }}</span
                >
              </div>
              <div class="sub_total" v-if="confirm.discount > 0">
                <span>Discount</span>
                <span style="float: right"
                  >- Rp {{ formatCurrency(confirm.discount) }}</span
                >
              </div>

              <div class="total">
                <span>Total Price</span>
                <span style="float: right"
                  >Rp {{ formatCurrency(confirm.price) }}</span
                >
              </div>

              <div style="margin: 20px">
                <span
                  style="color: rgb(16 161 161); text-decoration: underline"
                  @click="skShow()"
                >
                  I agree with the Terms and Conditions</span
                >
              </div>
            </div>
            <div class="footerButton" v-if="state != 0">
              <button class="buttonPrimary" style="width: 80%" @click="next()">
                {{ next_label }}
              </button>
            </div>

            <div v-if="skOrder" class="modal-mask" @click="skOrder = false">
              <!-- Modal content -->
              <div class="modal-content" v-on:click.stop="nothing()">
                <div class="modal-container">
                  <div class="modal-body mainContent">
                    <div>
                      <div class="text-center titlePayment">
                        <img
                          src="@/assets/ic_back.png"
                          class="backsk"
                          @click="skOrder = false"
                        />

                        <b>Term and Condition</b>
                      </div>
                    </div>
                    <div class="skContent" v-html="params.toc"></div>
                  </div>
                </div>
              </div>
            </div>
        </Layout>
        <!-- <keypad @setkeydata="getkey" @close="isshowkeypad = false" v-if="isshowkeypad"></keypad> -->
        <!-- <keypadfull></keypadfull>  -->
    </div>
</template>

<script>
import MyMixin from "../mixins/mixin";
import Layout from "./Layout.vue";
//import keypad from './keypad.vue'
//import keypadfull from './keypadfull.vue'
 
import "vue2-datepicker/index.css";
import "vue-select/dist/vue-select.css";

export default {
    name: "Food",
    components: {
        Layout,  
    },
    mixins: [MyMixin],
    props: [],
    data() {
        return {
            htaOrder : false ,
            errorMeetingPoin: "",
            timeDefault: "01:00",
            timeFormat: {
                start: "00:00",
                step: "00:15",
                end: "23:45",
                format: "HH:mm",
            },
            baggage: 0,
            resultdestination: [],
            filterdestination: "",
            modelSelected: "",
            inputed: {
                text: "",
                num: "",
            },
            state: 0,
            product: null,
            cs: null,
            params: {},
            locations: [],
            destinations: [],
            item: {},
            filterdestinationText: "",
            errormessage: null,
            customerid: null,
            confirm: null,
            meeting_point: "",
            next_label: "CHOOSE",
            destination: 0,
            date: null,
            time: "13:00",
            name: null,
            phone: null,
            now: 0,
            detail_size: 4,
            skOrder: false,
            selectedLocation: null,
            isshowkeypad: false,
        };
    },
    methods: { 
      choose(denom) {
      if (denom.status != 1) {
        return
      }
      if (!this.denom || this.denom.id != denom.id) {
        this.denom = denom
      }

      if (this.customerid != '') {
        this.onConfirm({
          //label_customerid: 'No HP',
          //customerid: this.customerid,
          price: this.denom.price,
          item: this.denom,
          product: this.product,
          name: this.denom.name,
          quota: this.denom.quota
        })
      }
    },
        childBack() {
            if (this.state <= 0) {
                return window.history.back();
            }
            this.state = this.state - 1;
            return true;
        },
        inquiry() {
            this.errormessage = "";
            let customerid = this.customerid.trim();
            if (customerid.length == 0) {
                return;
            }

            let self = this;
            this.$root.elLoadings(true, "Loading");
            this.$root
                .elPost("/trx/inquiry", {
                    customerid: customerid, 
                    product_code: this.product.code,
                    data: {
                        car: this.baggage,
                        meeting_point: this.meeting_point,
                        date: this.date,
                        time: this.time,
                        destination: this.destination,
                        from: this.selectedLocation ? 3 : 1,
                        additional: {
                            name: this.name,
                            phone: this.phone,
                        },
                    },
                })
                .then((data) => {
                    this.$root.elLoadings(false);
                    if (data.ec == 200) {
                        self.confirm = data.data;
                        self.state = 3;
                        self.next_label = "Continue to Payment";
                    } else {
                        this.errormessage = data.message;
                        //self.$root.elDialog(data.message)
                    }
                });
        },
        validateEmail(email) {
            if (email.lenth == 0) {
                return false;
            }
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        next() {
            if (0 == this.state) { 
                 
                    if (!this.customerid) {
                        this.errormessage = "Please fill the Email and phone number";
                        return;
                    }
                    if (!this.validateEmail(this.customerid)) {
                        this.errormessage = "Invalid Email address";
                    }   
                    if (!this.phone || this.phone.length < 10) {
                        this.errormessage = "Invalid phone number";
                        return;
                    } 
                this.next_label = "NEXT";
                this.inquiry();
            } else if (this.state == 3) {
                this.next_label = "PAYMENT";
                this.submit();
            }
        },
        
    },
    mounted() {
        this.product = this.loadObject("product");
      console.log("food.product", this.product);
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.noteProduct{
      margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #42b983;
    text-decoration: underline;
} 
.backsk{
  float: left; 
  width: 10px !important; 
  height: 20px !important;
}
.selectmeeting select:required:invalid {
    color: #c9c9c9;
}

.selectmeeting option[value=""][disabled] {
    display: none;
}

.selectmeeting option {
    color: black;
}

.mx-datepicker {
    width: 100%;
}

.mx-input2 {
    background: #f5f5f5;
    color: #111;
    width: 100%;
    font-weight: bold;
    height: 48px;
    font-size: 12pt;
    padding-left: 20px;
    padding-right: 80px;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 64px;
    background-repeat: no-repeat;

    background-size: 32px;
    background-position: 90% 50%;
}
</style>
<style scoped>
li {
    height: 20px;
    margin-left: 0px;
}

.vs__dropdown-option--highlight {
    width: 100%;
    margin-left: 0px;
}
</style>
<style scoped>
h3 {
    border-left: 0.25em solid #4a9f9f;
    padding-left: 0em;
}

img {
    width: 30pt;
    height: 30pt;
}

h4 {
    font-size: 16pt;
}

label {
    white-space: pre;
    font-weight: bold;
    margin-bottom: 10px;
}

.formInput {
    padding: 10px;
}

.item {
    min-height: 48pt;
    font-size: 13pt;
    padding: 2px;
    border-bottom: solid 1pt #f5f5f5;
    display: flex;
    align-items: center;
}

.item:active {
    background: #f5f5f5;
}

.icon {
    min-width: 36px;
    margin-right: 16px;
}

.confirmation {
    margin: 0.1em;
    padding: 0.1em;
}

.detail {
    color: #949494;
}

.total {
    margin-top: 0.3em;
    padding-top: 0.2em;
    border-top: 2px dashed #c4c4c4;
    font-weight: bold;
}

.full {
    color: #4a9f9f;
    font-weight: bold;
    padding: 0 0 0.25em;
}

.footerButton {
    text-align: center;
}

li {
    width: 100%;
    text-align: left;
}

ul {
    padding: 5px;
    margin: 0;
}
 
.ul-products {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.li-product {
  width: 40%;
  margin: 4pt 4pt 4pt 4pt;
  font-weight: bold;
  display: inline-block;
  background: #49C8F5;
  border-radius: 4px;

}

.li-product-active {
  background: #0769B3;
}

.product-denom {
  font-size: 20px;
  margin: 0pt 8pt 2pt 8pt;
  color: #fff;
}

.product-price {
  font-size: 14px;
  margin: 1pt 30pt 4pt 8pt;
  color: #fff;
}
</style>