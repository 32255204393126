import TransactionDetail from './components/TransactionDetail.vue'
import Bill from './components/Bill.vue'
import Pulsa from './components/Pulsa.vue'
import PulsaData from './components/PulsaData.vue'
import BillInquiry from './components/BillInquiry.vue'
import Poter from './components/Poter.vue'
import carrent from './components/carrent.vue'
import product from './components/product.vue'
import food from './components/food.vue'
import reprintstruk from './components/reprintstruk.vue'

export default {
    '/reprintstruk':{
        title: 'Reprint Struk',
        component:reprintstruk
    },
    '/detail': {
        title: 'Detail Transaksi',
        component:TransactionDetail
    },
    '/pulsa': {
        title: 'Pulsa',
        component:Pulsa
    },
    '/data': {
        title: 'Data',
        component:PulsaData
    },
    '/bill': {
        title: 'Bill',
        component:Bill
    },
    '/inqbill': {
        title: 'Bill',
        component:BillInquiry
    },
    '/poter': {
        title: 'Concierge',
        component:Poter
    },
    '/carrent': {
        title: 'carrent',
        component:carrent
    },
    '/product': {
        title: 'product',
        component:product
    },
    '/food': {
        title: 'food',
        component:food
    }
  }