<template>
  <div class="modal-content" v-on:click.stop="nothing()">
    <div class="modal-container">
      <div class="modal-body">
        <div class="text-center titleConfirmChekout">
            <b>Checkout Order</b>
        </div>
        <div class="titleNonte">Transaction Information</div>
        <div class="infoProduct">
            <div class="info-left">
                <div class="info">{{confirm.name}} {{confirm.quota}}</div>
                <div class="noteInfo">{{confirm.item.description}}</div>
            </div>
            <div class="info-right">
              <img :src="confirm.product.icon" class="imageOperator" />
            </div>
            <div class="clear"></div>
        </div>
        <div class="infoProductDetail">
            <div>
                <div class="noteInfo2">{{confirm.label_customerid}}</div>
                <div class="info">{{confirm.customerid}}</div>
            </div>
        </div>

        <div class=" Checkout" @click="methodePayment()">
            <div v-if="$root.payment">
              <div class="info-left confirm">
                  <div class="info" @click="methodePayment()">
                    <img src="@/assets/ic-payment-selection.svg"  class="logosk" />
                    <span class="payText">Bayar Dengan {{$root.payment.name}}</span>
                  </div>
              </div>
              <div class="info-right confirm">
                <img :src="$root.payment.icon" @click="methodePayment()" class="pg_icon confirm" />
                <img src="@/assets/ic-chevron-right-light.svg" @click="methodePayment()"   class="imageNextConfirm" />
              </div>
              <div class="clear"></div>
            </div>
            <div v-else class="titleNote">
                <div class="info-left confirm">
                  <img src="@/assets/ic-payment-selection.svg"  class="logosk" />
                  <span>Choose Payment Method</span>
                </div>
                <div class="info-right confirm">
                   <img src="@/assets/ic-chevron-right-light.svg" @click="methodePayment()"   class="imageNextConfirm" />
                </div>
                <div class="clear"></div>
            </div>
        </div>
        
        <input class="input-customerid" type="email" v-model.trim="email" v-if="!confirm.email"
                style="margin-top:5vmin;" placeholder="alamat email"
                />
        <i>E-ticket will be sent to your e-mail</i>
        <div class="footerDataConfirm">
            <div class="info-left"  style="width:70%">
                <div class="noteInfo2">
                  Total Price
                </div>
                <div class="totalPrice">Rp {{formatCurrency(confirm.price)}}</div>
            </div>
            <div class="info-right"  style="width:30%">
              <button class="buttonPrimary" @click="pay()" :disabled="!$root.payment">Pay</button>
            </div>
            <div class="clear"></div>
        </div>
      </div>
    </div>

    <div v-if="showQueryAccount" class="modal-mask" @click="showQueryAccount = false">
        <!-- Modal content -->
        <div class="modal-content" v-on:click.stop="nothing()">
          <div class="modal-container">
            <div class="modal-body">
              Masukkan no OVO Anda
              <input class="input-customerid" type="number" pattern="\d*" v-model.trim="account"
                style="margin-top:5vmin;" 
                />
                <button class="buttonPrimary" @click="submit()" :disabled="account.length<5" style="margin-top:5vmin;margin-bottom:10vmin">Bayar</button>
            </div>
          </div>
        </div>
    </div>

  </div>  
</template>

<script>
import MyMixin from '../mixins/mixin'

export default {
  name: 'ConfirmationOrder',
  mixins: [MyMixin],
  props: [
    'confirm'
  ],
  data () {
    return {
      account:'',
      showQueryAccount:false,
      email:''
    }
  },
  methods: {
    nothing() {
    },
    validateEmail(email) {
        if (email.lenth == 0) {
          return false
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    methodePayment () {
        this.$emit('onSelectPayment')
    },
    pay() {
      if (!this.$root.payment) {
        this.$root.elDialog('Pilih metode bayar')
        return
      }
      if (this.confirm.email) {
        this.email = this.confirm.email
      } else if (!this.validateEmail(this.email)) {
          this.$root.elDialog('Harap isi email dengan benar')
          return
        }
      if (this.$root.payment.code == 'OVO') {
        this.showQueryAccount = true
      } else {
        this.submit()
      }
    },
    submit() {
      console.log('submit')
      this.showQueryAccount = false
      let self = this
      this.$root.elLoadings(true, 'Loading')
      this.$root.elPost('/trx/submit',{
          trxid: this.confirm.trxid,
          customerid: this.confirm.customerid,
          product_id: this.confirm.item.id,
          payment_method: this.$root.payment.code,
          account: this.account,
          email: this.email
        })
          .then(data => {
            if (data.ec == 200) {
              console.log('submit',data.data)
              self.$root.elLoadings(false)
              /*if (data.data.redirect_url_htpp) {
                window.location.href = data.data.redirect_url_htpp
              } else if (data.data.redirect_url_http) {
                window.location.href = data.data.redirect_url_http
              } else if (data.data.redirect_url_app) {
                window.location.href = data.data.redirect_url_app
              }*/
              self.go('/detail?trxid=' + data.data.trxid, true)
            } else {
              self.$root.elLoadings(false)
              self.$root.elDialog(data.message)
            }
          });
    } 
  },
  mounted(){
    console.log('confirm', this.confirm)
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footerDataConfirm{
  margin-top: 16px;
}
.Checkout{
  padding: 12px 0;
  border-bottom: 1px solid #F5F5F5;
  border-top: 1px solid #F5F5F5;
}
.titleConfirmChekout{
  color: #111111;
  padding-bottom: 16px;
}

.Checkout.noBorderTop{
    border-top: none;
}
.Checkout:last-child {
    border-top: none;
}
.imageNextConfirm{
  margin: 0 5px;
}
.modal-body{
 padding: 16px;
}
.sk{
  font-size: 10px;
  color: #949494;
}
.pg_icon.confirm{height: 16px;}
.info-left.confirm{
      width: 60%;
}
.info-right.confirm{
      width: 40%;
}
.titleNote{
  color: rgb(16 161 161);
}
.logosk{
  width: 15px;
  margin: 5px 15px 0 4px;
  float: left;
}
.info .payText{
  font-weight: 500;
  font-size: 14px;
  color: #111111
}
.tnc{
  font-size: 10px;
  margin-top: 40px;
}
.imageOperator{
      height: 16px;
}
.titleNonte{
  margin-top: 16px;
border-left: rgb(28 184 184) 3px solid;
    padding-left: 8px;
    font-weight: bold;
    color: #000;
    font-size: 12px;
}
.titleConfirm{
  padding: 13px;
  color: #000;
}
.imgPayment{
  width: 30%;
  float: left;
  text-align: right;
  position: relative;
  top: 12px;
}
.notePayment{
  padding: 5px;
  margin-left: 10px;
  font-size: 10px;
  background: #4270ED;
  border-radius: 4px;
  color: #fff;
}
.titlePayment{
  width: 60%;
  float: left;
  padding: 20px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.033em;
  color: #111111;
  border-bottom:1px solid #F5F5F5;
}
.backButton{
  position: absolute;
  left: 20px;
}
.titlePopup{
  padding: 20px;
  text-align: center;
  font-weight: 600;
}
.totalPrice{
  height: 21px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color:rgb(16 117 117);

}
img.imagePaymentMethode {
  margin-top: 10px;
}
.logowalet{
  position: relative;
  margin-right: 14px;
  width: 24px;
  height: 24px;
}
.logosk{
  margin: 0px 14px 0 0px;
  float: left;
  height: 24px;
  width: 24px;
}
.noteInfo2{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: #949494;
}
.noteInfo{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.78);
}
.infoProduct {
    margin-top: 24px;
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 16px;

}
.infoProductDetail{
  margin-top: 16px;
  margin-bottom: 16px;
}

.info-left{
  width: 75%;
  float: left;
}
.info-right{
  width: 25%;
  float: right;
  text-align: right;
}
.info{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.titleData{
  width: 103px;
  height: 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
</style>
