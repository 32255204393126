<template>
  <Layout :hasBack="true" :title="product?product.name:''"   @onBack="childBack">
    <div style="margin-top:5vmin;">
      <h4 v-if="product">
          {{product.label}}
          <img :src="product.icon" class="imageOperator" style="float:right">
      </h4>
    </div>
    <input class="input-customerid" type="number" pattern="\d*" v-model.trim="customerid" @input="onchange" style="background-position: 95% 50%;" 
      :style="{ backgroundImage: 'url(' + operator_img + ')' }" ref="inputcustomerid" placeholder="Contoh:1234567890"
      />
    <div class="error" v-if="errormessage">{{errormessage}}</div>
    <div v-if="product">
      <div v-if="customerid.length > 4" class="ul-products">
        <div v-for="d in product.item" :key="d.denom" v-bind:class="{ 'li-product-active': denom && denom.id == d.id, 'li-product-inactive': d.status == 2}" @click="select(d)" class="li-product">
          <div class="product-denom">{{d.quota}}</div>
          <div class="product-price">Rp. {{formatCurrency(d.price)}}</div>
        </div>
        <div v-if="denoms.length % 2 == 1" style="width: 40%;margin: 4pt;">
        </div>
    </div>
      <div v-if="confirm">
          <h3>Tagihan kamu</h3>
          <div class="detail">
            <div v-for="info in confirm.info" :key="info.key">
              <span >{{info.label}}</span>
              <span style="float:right;">{{info.value}}</span>
            </div>
          </div>
          <div class="total">
            <span >Total Tagihan</span>
            <span style="float:right;">Rp {{formatCurrency(confirm.price)}}</span>
          </div>
      </div>
      <div class="footerbutton">
        <button  v-if="confirm" class="buttonPrimary" style="width:80%" @click="submit()">Lanjut Bayar</button>
      </div>
    </div>
  </Layout>
</template>

<script>
import MyMixin from '../mixins/mixin'
import PulsaMixin from '../mixins/pulsa'
import Layout from './Layout.vue'
export default {
  name: 'BillInquiry',
  components: { 
    Layout
  },
  mixins: [MyMixin, PulsaMixin],
  props: [],
  data() {
    return {
      confirm:null,
      product:''
    }
  },
  methods: {
    select(d) {
      if (d.status == 2) {
        return
      }
      this.denom = d
      this.inquiry()
    },
    inquiry() {
      if (!this.denom) {
        return
      }
      let customerid = this.customerid.trim()
      if (customerid.length < 4) {
        return
      }
      let self = this
      this.$root.elLoadings(true, 'Loading')
      this.$root.elPost('/trx/inquiry',{
          customerid: customerid,
          product_id: this.denom.id
        })
          .then(data => {
            this.$root.elLoadings(false)
            if (data.ec == 200) {
              self.confirm = data.data
            } else {
              self.errormessage = data.message
            }
          });
    },
    submit() {
      this.confirm.product_id = this.denom.id
      this.confirm.label_customerid = this.product.label
      this.onConfirm(this.confirm)
    }
  },
  mounted() {
    this.product = this.loadObject('product')
    console.log('billinq.product', this.product)
    document.title = this.product.name
    if (localStorage.defaultcustomerid) {
      this.customerid = localStorage.defaultcustomerid
      this.onchange()
    }
    this.$refs.inputcustomerid.focus();
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.detail {
  background:#FAFAFB;
  margin:4px ;
  padding:4px;
}

.ul-products {
  overflow-x: hidden;
  overflow-y: hidden;
}
.li-product {
    width: 45%;
    background: #49C8F5;
    padding: 2px 6px;
    margin: 5px;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    display: inline-block;
}
.li-product-active {
  background: #0C8C8C
}
.li-product-inactive {
  opacity: 0.25;
}
.product-denom {
  font-size: 24px;
  margin: 8pt 2pt 30pt 1pt;
  color:#fff;
}
.product-price {
  font-size: 14px;
  margin: 1pt 30pt 4pt 8pt;
  color:#fff;
}
.total {
  margin-top: 0.3em;
  padding-top: 0.2em;
  border-top: 2px dashed #C4C4C4;
  font-weight: bold;
}
.footerButton {
  text-align: center;
}
</style>
