<template>
  <div>
    <div class="header">
      <div :style="haderRedStyle">
        <img src="@/assets/ap_logo.png" class="logo" />
      </div>
        <splide class="banner" :options="primaryOptions"  :slides="ads" ref="primaryOptions" style="width:100%" v-if="ads.length>0">
          <splide-slide v-for="a in ads" v-bind:key="a.id"  style="height:180px" >
            <img :src="a.url" class="banner-img"/>
          </splide-slide>
        </splide>
    </div>
    <div class="contentchild">
        <div v-if="hasBack" style="text-align:center;pading-bottom:10px;">
          
          <div class="titlePage">
            <div @click="back()" class="btngradient mr-5 imgback">
              <img src="@/assets/back.png"><span>Back</span> 
            </div>
            <div style="font-weight:bold;padding-top:4px;">{{title}}</div>
          </div>
          
        </div>
        <slot></slot>
    </div>
    <div v-if="$root.isConfirm" class="modal-mask" @click="closeConfirm()">
          <ConfirmationOrder :confirm="$root.confirmationData"  @onSelectPayment="showPayment()"/>
    </div>

    <div v-if="$root.isPayment" class="modal-mask">
        <!-- Modal content -->
        <div class="modal-content" v-on:click.stop="nothing()">
          <div class="modal-container">
            <div class="modal-body mainContent">
              <div>
                <div class="text-center titlePayment">
                  <img src="@/assets/ic_back.png" style="float:left" @click="closePayment()">
                  <b>Choose Payment Method</b>
                </div>
              </div>
              <div class="paymentBox">
                <div v-for="p in $root.payments" :key="p.code" class="payment" @click="choosePayment(p)">
                  <img :src="p.icon" class="pg_icon" >
                  <div class="info-right-payment" v-if="$root.payment && $root.payment.code == p.code">
                    <div>Terakhir Dipilih</div>
                  </div>
                  <!-- <div class="info-right-payment-noneActive">
                    <div>Tidak Aktif</div>
                  </div> -->
                  
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div v-if="$root.elIsDialog" class="modal-mask" v-on:click.stop="nothing()" style="z-index: 1100;">
      <div class="modal-container-center">
        <div class="modal-body">
          <div style="text-align:center;">
            <div style="padding:8px;">{{$root.elMessage}}</div>
            <div><button class="buttonPrimary" @click="dialogClose()">Tutup</button></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$root.elIsLoading" class="modal-mask" v-on:click.stop="nothing()" style="z-index: 1200;">
      <div class="modal-container-center">
        <div class="modal-body">
          <div style="text-align:center;">
            <img src="@/assets/loading.gif">
            <div>{{$root.elMessage}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
          <i>Customer Service : 
            <span v-if="csNumber">{{csNumber}}</span>
            <span v-else>0811-1515565 (Whatsapp Only)</span></i>
    </div> -->
  </div>
</template>
<script>

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import ConfirmationOrder from './ConfirmationOrder.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'Layout',
  components: {
      ConfirmationOrder,
      Splide,
      SplideSlide,
  },
  props:['title','hasBack','csNumber'],
  mixins: [],
  data() {
    return {
      csnumber:'',
      ads:[
        {
          id:0,
          name:'',
          url:'https://line.elebox.id/img/banner/default.png'
        }
      ],
      primaryOptions: {
          rewind: true,
          arrows:false,
          width: '100%',
          perPage: 1,
          gap: '1rem',
          fixedHeight : 190,
        }
    }
  },
  watch: {
   '$root.menuCreated': function(value){
      console.log('layout watch liffcreated',value,this.$root.ads);
      this.ads = this.$root.ads
    }
  },
  computed: {
    haderRedStyle() {
      return {
        'background': process.env.VUE_APP_THEME_COLOR, 
        }
    }
  },
  methods: {
    back() {
      if (this.$emit('onBack')) {
        console.log('layoyt.back')
        return
      }else{
              console.log('layoyt.back 2')

        window.history.back()
      }
    },
    nothing() {
    },
    dialogClose(){
      this.$root.elIsDialog = false
      if (this.$root.elDialogCallback) {
        this.$root.elDialogCallback()
      }
    },
    onConfirm(confirm) {
      console.log('onConfirm', confirm)
      this.confirm = confirm
      this.$root.isConfirm = true
      window.history.pushState(
                  null,
                  this,
                  '#confirm'
                )
    },
    closeConfirm() {
      window.history.back()
    },
    showPayment() {
      this.$root.isPayment = true
      window.history.pushState(
                  null,
                  this,
                  '#payment'
                )
    },
    closePayment() {
      window.history.back()
    },
    choosePayment(payment) {
        console.log('choosePayment',payment)
      this.$root.payment = payment
      //this.$root.isPayment = false
      window.history.back()
    }
  },
  mounted() {
      this.ads = this.$root.ads
  }
}
</script>

<style>
.imgback img{
  width: 20px;
  margin-right: 10px;
}
.imgback{
  display: flex;
  align-items: center;
  justify-content: center;
}
.titlePage{
  display: flex;
}
.footer {
  text-align: center;
}
@media screen and (max-width: 800px) {
  .banner {
    position:absolute;
    top:70px;
    height: 120px;
  }
  .banner-img {
    width: 80%;
    border-radius: 10px;
  }
  .header {
    height: 230px;
    text-align: center;
  }
}

@media screen and (min-width: 800px) {
  .banner {
    position:absolute;
    top:70px;
    height: 180px;
  }
  .banner-img {
    width: 40%;
    border-radius: 10px;
  }
  .header {
    height: 280px;
    text-align: center;
  }
}

.header_title {
  margin: auto;
  padding-top:30px;
  text-align: center;
  color: #FFF;
  font-size: 20pt;
  font-style: bold;
}
.logo {
  width: 100px;
  text-align: center;
}

.pg_icon{
  max-width:100% ;
}
.titlePayment{
  font-size: 16px;
  color: rgb(17 17 17);
  font-weight: bold;
}

.paymentBox{
    margin-top: 30px;
}
.paymentBox .payment{
  border-bottom: 1px solid #f1f1f1;
  padding: 24px 20px;
  vertical-align: middle;
}

.paymentBox .payment:last-child{
      border-radius: 7px;
    border: 1px solid #f1f1f1;
}
.mainContent{
  min-height: 560px;
}
h1 {
  color: #111;
  font-size: 1.5em;

}
h1 .green {
  color: #07B53B;
}
h5 {
  color:#dcdcdc;
  font-size: 14px;
}
.loading {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
}


.contentchild {
  margin-top:25px;
    padding:16px;
}
 
.info-right{
  width: 25%;
  float: right;
  text-align: right;
}

.info-right-payment{
  width: 30%;
  float: right;
  text-align: center;
  background: rgb(16 117 117);
  color: #fff;
  font-size: 11px;
  padding:5px 3px ;
  border-radius: 15px;
}

.info-right-payment-noneActive{
  width: 30%;
  float: right;
  text-align: center;
  background: rgb(200 200 200);
  color: #fff;
  font-size: 11px;
  padding:5px 3px ;
  border-radius: 15px;
}
</style>
